import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Photo } from '@/models/preAnalysis/photo';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';
import axios from 'axios';

Vue.use(Vuex);

interface State {
    photos: Photo[];
    photosOverview: Photo[];
}

const mutations: MutationTree<State> = {
    setPhotos: (currentState, photos) => (currentState.photos = photos),
    setPhotosOverview: (currentState, photos) => (currentState.photosOverview = photos),
};

const actions: ActionTree<State, any> = {};

const getters = {
    photos(currentState: State) {
        return currentState.photos;
    },

    photosOverview(currentState: State) {
        return currentState.photosOverview;
    },

    coverPhoto: (currentState: State) => (proposalMatch: ProposalMatch) => {
        // Filter out all other proposal photos
        return currentState.photos.find(function(photo: Photo) {
            return photo.type === 'Proposal' && photo.proposal_id === proposalMatch.proposal_id;
        });
    },
};

const state: State = {
    photos: Array<Photo>(),
    photosOverview: Array<Photo>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
