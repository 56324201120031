















import { Component, Vue } from 'vue-property-decorator';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { Getter } from 'vuex-class';
import { Material } from '@/models/configurator/material';

@Component({
    name: 'OfferConstructionMethodChooser',
})
export default class OfferConstructionMethodChooser extends Vue {
    @Getter('configuratorConstructionMethod/diy') private diy: ConstructionMethod;
    @Getter('configuratorConstructionMethod/profi') private profi: ConstructionMethod;

    @Getter('configuratorResult/selectedMaterial') private selectedMaterial: Material;
    @Getter('configuratorMaterial/materials') private materials: Material[];

    get selectedConstructionMethod() {
        return this.$store.getters['configuratorResult/selectedConstructionMethod'];
    }

    set selectedConstructionMethod(constructionMethod: ConstructionMethod) {
        // this.removeNonSupportedOptions();
        this.$store.commit('configuratorResult/setConstructionMethod', constructionMethod);

        if (constructionMethod.name === 'diy') {
            const holz = this.materials.find((m) => m.name === 'holz');

            // Always select holz
            if (holz) {
                this.$store.commit('configuratorResult/setMaterial', holz);
            }
        }
    }
}
