











import { Component, Prop, Vue } from 'vue-property-decorator';
import EditTopicModal from './EditTopicModal.vue';
import { Topic } from '@/models/configurator/topic';
import { Getter } from 'vuex-class';

@Component({
    name: 'EditTopicModalButton',
    components: {
        EditTopicModal,
    },
})
export default class EditTopicModalButton extends Vue {
    @Prop()
    private topic: Topic;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    private showModal: boolean = false;

    private toggleEditTopicModal() {
        this.showModal = !this.showModal;
    }
}
