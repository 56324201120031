import axios from 'axios';
import HttpException from '@/webservices/configurator/HttpException';
import { PriceType } from '@/models/configurator/priceType';

class PriceTypeWebservice {
    public async getAll(): Promise<PriceType[]> {
        try {
            const response = await axios.get<PriceType[]>('/editor/v1/price_types');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const priceTypeWebservice = new PriceTypeWebservice();
