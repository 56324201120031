























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'PhotosFinishedModal',
})
export default class PhotosFinishedModal extends Vue {
    @Prop() private isModalActive: boolean;

    private toggleModal() {
        this.$store.commit('preAnalysisWizard/setLaterGoToResults', true);
        this.$emit('toggleModal');
    }

    private goToResults() {
        this.$emit('goToResults');
    }
}
