import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { materialWebservice } from '@/webservices/configurator/material';
import { Material } from '@/models/configurator/material';

Vue.use(Vuex);

interface State {
    materials: Material[];
}

const mutations: MutationTree<State> = {
    setMaterials: (currentState, materials) => (currentState.materials = materials),

    resetState(currentState) {
        currentState.materials = Array<Material>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchMaterials({ commit }) {
        try {
            const materials = await materialWebservice.getAll();
            commit('setMaterials', materials);
        } catch (error) {
            console.error('Failed loading in the Webservice: [' + error.status + ']: ' + error.message);
            // Initialize the topics empty
            commit('setMaterials', []);
        }
    },
};

const getters = {
    materials(currentState: State) {
        return currentState.materials;
    },
};

const state: State = {
    materials: Array<Material>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
