import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { configuratorPriceWebservice } from '@/webservices/configurator/configuratorPrice';
import { ConfiguratorPrice } from '@/models/configurator/configuratorPrice';

import { Result } from '@/models/configurator/result';
import { PriceHelper } from '../helpers/price/PriceHelper';

@Component({
    name: 'OfferBase',
})
export class OfferBase extends Vue {
    @Getter('configuratorResult/result') protected result: Result;

    protected priceHelper: PriceHelper = new PriceHelper();
    private configuratorPrices: ConfiguratorPrice[] = new Array<ConfiguratorPrice>();

    protected async getDimensionMaterialPrice() {
        this.configuratorPrices = await configuratorPriceWebservice.getConfiguratorPrices();
    }

    @Watch('basePrice')
    private onBasePriceChange(value: number) {
        this.$store.commit('configuratorResult/setBasePrice', value);
    }

    @Watch('totalPrice')
    private onTotalPriceChange(value: number) {
        this.$store.commit('configuratorResult/setTotalPrice', value);
    }

    @Watch('totalOptionPrice')
    private onTotalOptionsPriceChange(value: number) {
        this.$store.commit('configuratorResult/setTotalOptionsPrice', value);
    }

    protected get basePrice() {
        let configuratorPrice: ConfiguratorPrice | undefined = {} as ConfiguratorPrice;

        if (this.result.product.name === 'schwimmteich') {
            configuratorPrice = this.configuratorPrices.find((el) => {
                return (
                    this.result.dimension.id === el.dimension_id &&
                    this.result.material.id === el.material_id &&
                    this.result.product.id === el.product_id &&
                    this.result.constructionMethod.id === el.construction_method_id
                );
            });
        } else if (this.result.product.name === 'naturpool') {
            configuratorPrice = this.configuratorPrices.find((el) => {
                return (
                    this.result.dimension.id === el.dimension_id &&
                    this.result.material.id === el.material_id &&
                    this.result.product.id === el.product_id &&
                    this.result.form?.id === el.form_id
                );
            });
        } else {
            configuratorPrice = this.configuratorPrices.find((el) => {
                return (
                    this.result.dimension.id === el.dimension_id &&
                    this.result.material.id === el.material_id &&
                    this.result.product.id === el.product_id
                );
            });
        }

        if (configuratorPrice !== undefined) {
            return configuratorPrice.price;
        } else {
            return 0;
        }
    }

    protected get totalPrice() {
        let totalPrice: number = this.basePrice;

        this.result.topics.forEach((topic) => {
            topic.options.forEach((option) => {
                totalPrice += this.priceHelper.calculateOptionPrice(option, this.result);
            });
        });

        return totalPrice;
    }

    protected get totalOptionPrice() {
        return this.totalPrice - this.basePrice;
    }

    private mounted() {
        this.getDimensionMaterialPrice();
    }
}
