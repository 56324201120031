import axios from 'axios';
import { Result as CrResult } from '@/models/configurator/result';
import { Result as PAResult } from '@/models/preAnalysis/result';
import HttpException from './HttpException';

class ResultWebservice {
    /**
     * Call the result store method on api
     *
     * @param result Result
     */
    public async store(crResult: CrResult, pAResult: PAResult | null): Promise<CrResult> {
        try {
            const response = await axios.post<CrResult>('/configurator/v1/results/store', {
                crResult,
                pAResult,
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong storing the data');
        }
    }
}

export const resultWebservice = new ResultWebservice();
