



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Option } from '@/models/configurator/option';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import { Action, Getter } from 'vuex-class';
import { DeleteOptionDto } from '@/models/editor/dto/DeleteOptionDto';

@Component({
    name: 'DeleteOptionButton',
    components: {
        ConfirmModal,
    },
})
export default class DeleteOptionButton extends Vue {
    @Action('editorOption/deleteOption')
    private deleteOption;
    @Prop()
    private option: Option;
    private showModal: boolean = false;
    private deleting: boolean = false;

    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    private toggleConfirmModal() {
        this.showModal = !this.showModal;
    }

    private async removeOption(shouldDelete: boolean) {
        if (shouldDelete) {
            this.deleting = true;
            await this.deleteOption({
                id: this.option.id,
                topic_id: this.option.topic_id,
            } as DeleteOptionDto);
        }
        this.toggleConfirmModal();
    }

    get confirmText() {
        return 'Sind Sie sicher, dass Sie die Option: "' + this.option.text + '" löschen möchten?';
    }
}
