import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { constructionMethodWebservice } from '@/webservices/configurator/constructionMethod';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';

Vue.use(Vuex);

interface State {
    constructionMethods: ConstructionMethod[];
}

const mutations: MutationTree<State> = {
    // tslint:disable-next-line:max-line-length
    setConstructionMethods: (currentState, constructionMethods) =>
        (currentState.constructionMethods = constructionMethods),

    resetState(currentState) {
        currentState.constructionMethods = Array<ConstructionMethod>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchConstructionMethods({ commit }) {
        try {
            const constructionMethods = await constructionMethodWebservice.getAll();
            commit('setConstructionMethods', constructionMethods);
        } catch (error) {
            console.error('Failed loading in the Webservice: [' + error.status + ']: ' + error.message);
            // Initialize the topics empty
            commit('setConstructionMethods', []);
        }
    },
};

const getters = {
    constructionMethods(currentState: State) {
        return currentState.constructionMethods;
    },

    diy(currentState: State) {
        return currentState.constructionMethods.find((cm) => cm.name === 'diy');
    },

    profi(currentState: State) {
        return currentState.constructionMethods.find((cm) => cm.name === 'profi');
    },
};

const state: State = {
    constructionMethods: Array<ConstructionMethod>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
