














































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CreateTopicDto } from '@/models/editor/dto/CreateTopicDto';
import { Scope } from '@/models/common/scope';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import Multiselect from 'vue-multiselect';
import UploadImages from '@/components/prine/UploadImages.vue';
import { editorTopicWebservice } from '@/webservices/editor/topic';
import { EditTopicDto } from '@/models/editor/dto/EditTopicDto';

@Component({
    name: 'CreateTopicModal',
    components: {
        ConfirmModal,
        Multiselect,
        UploadImages,
    },
})
export default class CreateTopicModal extends Vue {
    @Action('editorTopic/createTopic') private createTopic;
    @Action('configuratorTopic/fetchAddonTopics') private fetchAddonTopics;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    @Getter('scope/scopes') private scopes: Scope[];

    private defaultTopicDto: CreateTopicDto = {
        title: '',
        description: '',
        required: false,
        multianswer: false,
        published: false,
        scope_id: null,
        topic_images: [],
    };
    private topicDto = this.defaultTopicDto;
    private showConfirmModal: boolean = false;
    private selectedScope: Scope | null = null;
    private changes: boolean = false;
    private saving: boolean = false;
    private validated: boolean = false;
    private images_file: File[] = [];

    private created() {
        this.resetTopic();
    }

    private async createTopicDto() {
        if (this.validate()) {
            this.saving = true;
            const createdTopic = await this.createTopic(this.topicDto);
            await this.saveTopicImages(createdTopic);
            await this.fetchAddonTopics();
            this.resetTopic();
            this.$emit('toggleCreateTopicModal');
        }
    }

    private async saveTopicImages(createdTopic: EditTopicDto) {
        const formData = new FormData();
        for (const file of this.images_file) {
            formData.append('images[]', file, file.name);
        }
        if (this.images_file.length > 0) {
            await editorTopicWebservice.saveTopicImages(createdTopic, formData);
        }
    }

    private validate() {
        this.validated = true;
        return (this.$refs.titleField as any).checkHtml5Validity() && this.isScopeValid;
    }

    private resetTopic() {
        this.topicDto = Object.assign({}, this.defaultTopicDto);
    }

    private toggleCreateTopicModal() {
        if (this.changes) {
            this.showConfirmModal = true;
        } else {
            this.$emit('toggleCreateTopicModal');
        }
    }

    private confirmModal(confirm: boolean) {
        if (confirm) {
            this.$emit('toggleCreateTopicModal');
        } else {
            this.showConfirmModal = false;
        }
    }

    private updateScope() {
        this.changes = true;
        if (this.selectedScope) {
            this.topicDto.scope_id = this.selectedScope.id;
        } else {
            this.topicDto.scope_id = null;
        }
    }

    get isScopeValid() {
        return this.topicDto.scope_id != null;
    }
}
