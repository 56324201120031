












import { Component, Prop, Vue } from 'vue-property-decorator';
import EditOptionModal from './EditOptionModal.vue';
import { Option } from '@/models/configurator/option';
import { Getter } from 'vuex-class';

@Component({
    name: 'EditOptionModalButton',
    components: {
        EditOptionModal,
    },
})
export default class EditOptionModalButton extends Vue {
    @Prop()
    private option: Option;
    @Prop()
    private isTopicScopeIntern: boolean;
    private showModal: boolean = false;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    private toggleEditOptionModal() {
        this.showModal = !this.showModal;
    }
}
