import axios from 'axios';
import HttpException from './HttpException';
import { Form } from '@/models/configurator/form';

class FormWebservice {
    public async getAll(): Promise<Form[]> {
        try {
            const response = await axios.get<Form[]>('/configurator/v1/forms');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const formWebservice = new FormWebservice();
