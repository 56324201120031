import Vue from 'vue';
import Vuex, { ActionTree, MutationTree } from 'vuex';
import { editorOptionWebservice } from '@/webservices/editor/option';
import { Option } from '@/models/configurator/option';
import { CreateOptionDto } from '@/models/editor/dto/CreateOptionDto';
import { EditOptionDto } from '@/models/editor/dto/EditOptionDto';
import { DeleteOptionDto } from '@/models/editor/dto/DeleteOptionDto';

Vue.use(Vuex);

interface State {} // tslint:disable-line

const mutations: MutationTree<State> = {};

const actions: ActionTree<State, any> = {
    async createOption({ commit, dispatch }, optionDto: CreateOptionDto): Promise<Option> {
        const option = await editorOptionWebservice.createOption(optionDto);
        dispatch('configuratorTopic/addOptionToTopic', option, { root: true });
        return option;
    },

    async saveOption({ commit, dispatch }, optionDto: EditOptionDto): Promise<Option> {
        const option = await editorOptionWebservice.saveOption(optionDto);
        dispatch('configuratorTopic/updateOptionInTopic', option, { root: true });
        return option;
    },

    async deleteOption({ commit, dispatch }, optionDto: DeleteOptionDto): Promise<Option> {
        const option: Option = await editorOptionWebservice.deleteOption(optionDto);
        dispatch('configuratorTopic/removeOptionInTopic', option, { root: true });
        return option;
    },
};

const getters = {};

const state: State = {};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
