





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CreateOptionDto } from '@/models/editor/dto/CreateOptionDto';
import { Topic } from '@/models/configurator/topic';
import Price from '@/models/configurator/price';
import OptionForm from '@/components/editor/option-editor/OptionForm.vue';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import { editorOptionWebservice } from '@/webservices/editor/option';
import { EditOptionDto } from '@/models/editor/dto/EditOptionDto';

@Component({
    name: 'CreateOptionModal',
    components: {
        OptionForm,
        ConfirmModal,
    },
})
export default class CreateOptionModal extends Vue {
    @Action('editorOption/createOption') private createOption;
    @Action('configuratorTopic/fetchAddonTopics') private fetchAddonTopics;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    @Getter('scope/internScopeId') private internScopeId: number;
    private images_file: File[] = [];

    @Prop()
    private topic: Topic;
    @Prop()
    private isTopicScopeIntern: boolean;
    private defaultOptionDto: CreateOptionDto = {
        text: '',
        description: '',
        price: {
            biopool: null,
            naturpool: null,
            schwimmteich: null,
            schwimmteich_diy: null,
        } as Price,
        option_type_id: null,
        price_type_id: null,
        topic_id: this.topic.id,
        construction_method_ids: [],
        product_ids: [],
        published: false,
        scope_id: this.isTopicScopeIntern ? this.internScopeId : null,
        option_images: [],
    };
    private optionDto: CreateOptionDto = this.defaultOptionDto;
    private showConfirmModal: boolean = false;
    private changes: boolean = false;

    private created() {
        this.optionDto.scope_id = this.isTopicScopeIntern ? this.internScopeId : null;
    }

    private mounted() {
        this.resetOption();
    }

    private async saveValidatedOption(optionToCreate: CreateOptionDto) {
        const updatedOption = await this.createOption(optionToCreate);
        await this.saveOptionImages(updatedOption);
        await this.fetchAddonTopics();
        this.$emit('toggleCreateOptionModal');
    }

    private async saveOptionImages(updatedOption: EditOptionDto) {
        const formData = new FormData();
        for (const file of this.images_file) {
            formData.append('images[]', file, file.name);
        }
        if (this.images_file.length > 0) {
            await editorOptionWebservice.saveOptionImages(updatedOption, formData);
        }
    }

    private resetOption() {
        this.optionDto = Object.assign({}, this.defaultOptionDto);
        this.images_file = [];
    }

    private toggleCreateOptionModal() {
        if (this.changes) {
            this.showConfirmModal = true;
        } else {
            this.$emit('toggleCreateOptionModal');
        }
    }

    private confirmModal(confirm: boolean) {
        if (confirm) {
            this.$emit('toggleCreateOptionModal');
        } else {
            this.showConfirmModal = false;
        }
    }
}
