import { User } from '@/models/common/user';
import axios from 'axios';

class UserWebservice {
    /**
     * Call the login method on api
     *
     * @param username Email address
     * @param password Password
     */
    public login(email: string, password: string) {
        return axios.post('/common/v1/auth/login', {
            grant_type: 'password',
            email,
            password,
        });
    }

    public getUserInfo() {
        return axios.get('/common/v1/auth/userinfo');
    }

    public logout() {
        return axios.delete('/common/v1/auth/logout');
    }

    public update(user: User) {
        return axios.post('/common/v1/auth/updateAccount', {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            current_password: user.password,
            new_password: user.new_password,
        });
    }

    public forgotPassword(email: string) {
        return axios.post('/common/v1/auth/forgotPassword', {
            email,
        });
    }

    public resetPassword(token: string, user: User) {
        return axios.post('/common/v1/auth/resetPassword', {
            token,
            password: user.password,
            new_password: user.new_password,
        });
    }
}

export const userWebservice = new UserWebservice();
