import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { OptionType } from '@/models/configurator/optionType';
import { optionTypeWebservice } from '@/webservices/editor/optionType';

Vue.use(Vuex);

interface State {
    optionTypes: OptionType[];
}

const mutations: MutationTree<State> = {
    setOptionTypes: (currentState, optionTypes) => (currentState.optionTypes = optionTypes),

    resetState(currentState) {
        currentState.optionTypes = Array<OptionType>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchOptionTypes({ commit }) {
        const optionTypes = await optionTypeWebservice.getAll();
        commit('setOptionTypes', optionTypes);
    },
};

const getters = {
    optionTypes(currentState: State) {
        return currentState.optionTypes;
    },
};

const state: State = {
    optionTypes: Array<OptionType>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
