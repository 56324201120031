export class SelectionDataHelper {
    public getProductDescription(name: string) {
        if (name === 'schwimmteich') {
            return 'Die biologische Wasseraufbereitung bei Schwimmteichen funktioniert nach dem ökologischen Leitbild eines Stillgewässers. Die natürlichen Vorbilder sind dabei ein See, ein tiefer Weiher oder ein Altarm eines Mittellandflusses. Die biologischen Filterarten beschränken sich dabei auf Filtervorgänge im Zusammenhang mit Wasserpflanzen, sogenannte Hydrobotanische Anlagen. Der Anteil solcher Wasserpflanzenzonen an der Gesamtwasserfläche beträgt im Minimum 40%.  Je nach Wunsch werden zusätzliche mechanische Vor- oder Feinfilter eingesetzt. Die Badezone ist dabei nur durch eine Wand unter Wasser von der bepflanzten Zone abgetrennt, oberflächlich sind die verschiedenen Zonen verbunden. Das Wasser in einem Schwimmteich kann zwischenzeitlich trüb sein, jedoch zeichnen sich diese Badeanlagen durch gesundes und blühendes Pflanzenwachstum und eine hohe Artenvielfalt bei den Tieren aus. <br/><br/>Sind Sie unsicher, ob ein Schwimmteich das richtige Produkt für Sie ist? Machen sie hier die Voranalyse <router-link to="/preanalysis">(Link)</router-link>.';
        }

        if (name === 'naturpool') {
            return 'Ein Naturpool verbindet die beiden Vorteile aus dem Produkt Schwimmteich und dem Produkt Naturpool. Das Wasser im Badebereich wird gleich wie bei einem Biopool über einen Biofilter mit hohem Technikeinsatz gereinigt. Die biologischen Vorgänge der Nährstoffminimierung im Badewasser entsprechen dabei dem ökologischen Leitbild eines Fliessgewässers. Die um den Badebereich liegenden Wasserpflanzenzonen sind jedoch hydraulisch vom Badebereich getrennt, in einem separaten Wasserbecken und funktionieren in der Wasseraufbereitung wie bei einem Schwimmteich. Damit wird eine biologische Badeanlage möglich, welche einerseits immer klares Wasser im Badebereich hat, anderseits im Pflanzenbereich gute Wachstumsbedingungen für Wasserpflanzen bietet. Allerdings sind Naturpool – Anlagen in der Bauweise deutlich aufwändiger als nur eine Schwimmteich oder nur ein Biopool. <br/><br/>Sind Sie unsicher, ob ein Schwimmteich das richtige Produkt für Sie ist? Machen sie hier die Voranalyse <router-link to="/preanalysis">(Link)</router-link>.';
        }

        if (name === 'biopool') {
            return 'Ein Biopool bietet alle Vorteile eines konventionellen Swimmingpools mit dem Unterschied, dass das Wasser nicht chemisch aufbereitet und somit desinfiziert wird. Eine technische Biofilter – Installation in Kombination mit mechanischen Vor- und Feinfiltern sorgt für immerzu klares Wasser in der Qualität von Trinkwasser. Die biologischen Vorgänge im Wasser sind dabei die gleichen wie bei einem Oberlauf eines Flusses. Daher spricht man auch von einer Wasseraufbereitung nach dem ökologischen Leitbild eines Fliessgewässers. Wasserpflanzen sind dafür nicht mehr notwendig und somit bei einem Biopool nicht vorgesehen. Dafür sind alle Zusatzausstattungen wie Gegenstromanlagen, Massagedüsen, Schwallwasserduschen oder Rollladenabdeckungen problemlos integrierbar. <br/><br/>Sind Sie unsicher, ob ein Schwimmteich das richtige Produkt für Sie ist? Machen sie hier die Voranalyse <router-link to="/preanalysis">(Link)</router-link>.';
        }
    }

    public getProductImage(name: string) {
        if (name === 'schwimmteich') {
            return [
                'img/reference/01_Schwimmteich/bd_170817_DSC_7728.jpg',
                'img/reference/01_Schwimmteich/bd_180527_DSC2714.jpg',
                'img/reference/01_Schwimmteich/bd_SALA_Imesch_034.jpg',
                'img/reference/01_Schwimmteich/DSC04023.jpg',
                'img/reference/01_Schwimmteich/konfigurator-schwimmteich-2.jpg',
            ];
        }

        if (name === 'naturpool') {
            return [
                'img/reference/02_Naturpool/DSC_0239.jpg',
                'img/reference/02_Naturpool/DSC00425.jpg',
                'img/reference/02_Naturpool/DSC04364.jpg',
                'img/reference/02_Naturpool/IMG_1329.jpg',
                'img/reference/02_Naturpool/konfigurator-naturpool-1.jpg',
            ];
        }

        if (name === 'biopool') {
            return [
                'img/reference/03_Biopool/22.06.2012_056.jpg',
                'img/reference/03_Biopool/24.07.2012_035.jpg',
                'img/reference/03_Biopool/IMG_2753_1.jpg',
                'img/reference/03_Biopool/konfigurator-biopool.jpg',
                'img/reference/03_Biopool/nachher.jpg',
            ];
        }
    }

    public getConstructionMethodDescription(name: string) {
        if (name === 'profi') {
            return 'Alle Arbeiten werden professionell von A-Z für Sie ausgeführt.';
        }

        if (name === 'diy') {
            return 'Ein durch unsere Schwimmteich-Profis entwickeltes und einfaches Eigenbausystem ermöglicht es Ihnen, den Traum vom eigenen Schwimmteich selbständig zu realisieren. Sie bauen eine schlüsselfertige Lösung und brauchen dazu keine Vorkenntnisse. Mehr Infos dazu finden Sie unter: <a href="www.schwimmteich-selber-bauen.ch" target="_blank">www.schwimmteich-selber-bauen.ch</a> ';
        }
    }

    public getFormDescription(name: string) {
        if (name === 'architectonic') {
            return 'Wählen Sie diese Form, wenn Sie das Pflanzbecken neben dem Schwimmbereich als klare, rechteckige Form im architektonischen Stil möchten. ';
        }

        if (name === 'organic') {
            return 'Wählen Sie diese Form, wenn die Pflanzfläche um das Schwimmbecken möglichst natürlich ausgestaltet und in freier Form ausgestaltet werden soll:';
        }
    }

    public getMaterialDescription(name: string) {
        if (name === 'holz') {
            return 'Die sichtbaren, senkrechten Wände und der Boden sind bei dieser Wahl aus gehobeltem Holz. Beim Holzbecken wird Schweizer Weisstannenholz aus regionaler und nachhaltiger Schweizer Forstwirtschaft eingesetzt.';
        }

        if (name === 'naturstein') {
            return 'Die sichtbaren und senkrechten Wände und der Boden sind bei dieser Auswahl aus gesägtem Naturstein. Für die Natursteinbecken verwenden wir entweder Calanca Gneis aus der Südschweiz oder Kalkstein aus Deutschland.';
        }

        if (name === 'kunstoff') {
            return 'Die sichtbaren und senkrechten Wände und der Boden sind bei dieser Auswahl aus Kunststoff gefertigt. Die Kunststoffbecken werden als fertig vorkonfektionierte Becken geliefert und sind aus umweltverträglichem Polyprobylen gefertigt und in unterschiedlichen Farben erhältlich.';
        }
    }

    public getMaterialImage(name: string) {
        if (name === 'holz') {
            return [
                'img/reference/Holzbecken/CAZU_Foto.jpg',
                'img/reference/Holzbecken/DSCPDC_0000_BURST20190816132505405.jpg',
                'img/reference/Holzbecken/konfigurator-schwimmteich-holzbecken-1.jpg',
            ];
        }

        if (name === 'naturstein') {
            return [
                'img/reference/Natursteinbecken/bd_SALA_Imesch_031.jpg',
                'img/reference/Natursteinbecken/DSC06269.jpg',
                'img/reference/Natursteinbecken/SchmidWettingen30.7.07.jpg',
            ];
        }

        if (name === 'kunstoff') {
            return [
                'img/reference/Kunststoffbecken/24.07.2012_019.jpg',
                'img/reference/Kunststoffbecken/IMG_2767.jpg',
                'img/reference/Kunststoffbecken/konfigurator-biopool-kunststoffbecken.jpg',
            ];
        }
    }
}
