import Vue from 'vue';
import Vuex from 'vuex';

// Import different modules
import account from './common/account/store';
import scope from './common/scope/store';

import configurator_wizard from './configurator/wizard/store';
import configurator_topic from './configurator/topic/store';
import configurator_result from './configurator/result/store';
import configurator_product from './configurator/product/store';
import configurator_constructionMethod from './configurator/constructionMethod/store';
import configurator_dimension from './configurator/dimension/store';
import configurator_material from './configurator/material/store';
import configurator_form from './configurator/form/store';

import pre_analysis_Topic from './preAnalysis/topic/store';
import pre_analysis_result from './preAnalysis/result/store';
import pre_analysis_wizard from './preAnalysis/wizard/store';
import pre_analysis_photo from './preAnalysis/photo/store';

import editor_topic from './editor/topic/store';
import editor_option from './editor/option/store';
import editor_price_type from './editor/priceType/store';
import editor_option_type from './editor/optionType/store';

// Utils
import utils from './utils/store';

import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],

    state: {},
    mutations: {},
    actions: {},
    modules: {
        // Common modules
        account,
        scope,
        // configurator modules
        configuratorWizard: configurator_wizard,
        configuratorTopic: configurator_topic,
        configuratorResult: configurator_result,
        configuratorProduct: configurator_product,
        configuratorConstructionMethod: configurator_constructionMethod,
        configuratorDimension: configurator_dimension,
        configuratorMaterial: configurator_material,
        configuratorForm: configurator_form,

        // pre_analysis modules
        preAnalysisTopic: pre_analysis_Topic,
        preAnalysisResult: pre_analysis_result,
        preAnalysisWizard: pre_analysis_wizard,
        preAnalysisPhoto: pre_analysis_photo,

        // editor modules
        editorTopic: editor_topic,
        editorOption: editor_option,
        editorPriceType: editor_price_type,
        editorOptionType: editor_option_type,

        // Utils
        utils,
    },
});
