



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'Footerino',
})
export default class Footerino extends Vue {}
