import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Topic } from '@/models/preAnalysis/topic';
import { Option } from '@/models/preAnalysis/option';
import axios from 'axios';

Vue.use(Vuex);

interface State {
    topics: Topic[];
    activeTopic: Topic;
}

const mutations: MutationTree<State> = {
    setTopics: (currentState, topics) => (currentState.topics = topics),
    setActiveTopic: (currentState, topic) => (currentState.activeTopic = topic),
};

const actions: ActionTree<State, any> = {};

const getters = {
    activeTopic(currentState: State) {
        return currentState.activeTopic;
    },

    topics(currentState: State) {
        return currentState.topics;
    },
};

const state: State = {
    topics: Array<Topic>(),
    activeTopic: {
        id: 0,
        title: '',
        description: '',
        options: Array<Option>(),
    },
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
