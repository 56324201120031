import { Topic } from '@/models/configurator/topic';
import { CreateTopicDto } from '@/models/editor/dto/CreateTopicDto';
import HttpException from '@/webservices/configurator/HttpException';
import { EditTopicDto } from '@/models/editor/dto/EditTopicDto';
import axios from 'axios';

class EditorTopicWebservice {
    public async order(topics: Topic[]): Promise<void> {
        try {
            const orders = topics.map((topic, index) => {
                return {
                    id: topic.id,
                    order: index,
                };
            });
            const response = await axios.post<void>('/editor/v1/topics/order', {
                orders,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async createTopic(topicDto: CreateTopicDto): Promise<Topic> {
        try {
            const response = await axios.post<Topic>('/editor/v1/topics', topicDto);
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async saveTopic(topicDto: EditTopicDto): Promise<Topic> {
        try {
            const response = await axios.put<Topic>('editor/v1/topics/' + topicDto.id, topicDto);
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async saveTopicImages(topicDto: EditTopicDto, topicImages: FormData): Promise<Topic> {
        try {
            const url = '/editor/v1/topics/' + topicDto.id + '/images';
            const response = await axios.post<Topic>(url, topicImages, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async deleteTopic(id: number) {
        try {
            const response = await axios.delete<Topic>('editor/v1/topics/' + id);
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }
}

export const editorTopicWebservice = new EditorTopicWebservice();
