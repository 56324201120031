import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';

Vue.use(Vuex);

interface State {
    currentTab: number;
}

const mutations: MutationTree<State> = {
    setCurrentTab: (currentState, currentTab) => (currentState.currentTab = currentTab),

    resetState(currentState) {
        currentState.currentTab = 0;
    },
};

const actions: ActionTree<State, any> = {};

const getters = {
    currentTab(currentState: State) {
        return currentState.currentTab;
    },
};

const state: State = {
    currentTab: 0,
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
