
























































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { mapGetters } from 'vuex';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';

import Swiper, { swiper, swiperSlide } from 'vue-awesome-swiper';

import { Photo } from '@/models/preAnalysis/photo';

@Component
export default class PhotoModal extends Vue {
    // Initialize to have values on render
    protected activePhoto: Photo = {
        id: 0,
        path: '',
        proposal_id: 0,
        type: 'Default',
    };

    // Data
    protected swiperOption = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        speed: 600,
        parallax: false,
        initialSlide: 0,
        effect: 'slide', // Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip"
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    // Props
    @Prop() private isModalActive: boolean;
    @Prop() private initialPhoto: Photo;
    @Prop() private activeIndex: number;

    // Vuex
    @Getter('preAnalysisPhoto/photosOverview') private photos: Photo[];
    @Getter('preAnalysisResult/photosLiked') private photosLiked: Photo[];
    @Getter('preAnalysisResult/photosDisliked') private photosDisliked: Photo[];

    @Getter('preAnalysisResult/isPhotoLiked') private isPhotoLiked;
    @Getter('preAnalysisResult/isPhotoDisliked') private isPhotoDisliked;

    @Mutation('preAnalysisResult/addLike') private addLike;
    @Mutation('preAnalysisResult/addDislike') private addDislike;

    @Mutation('preAnalysisResult/removeLike') private removeLike;
    @Mutation('preAnalysisResult/removeDislike') private removeDislike;

    private mounted() {
        this.activePhoto = this.initialPhoto;

        // Slide to the clicked image
        this.swiper.slideTo(this.activeIndex, 0, false);

        // Add a event listener so we know when a slide has changed
        this.addSwiperChangeListener();
    }

    private addSwiperChangeListener() {
        // When there is a change set the current active photo
        this.swiper.on('slideChange', () => {
            this.activePhoto = this.photos[this.swiper.activeIndex];
        });
    }

    get swiper() {
        return ((this.$refs.photoSwiper as any) as Swiper).swiper;
    }

    private like() {
        this.addLike(this.photos[this.swiper.activeIndex]);
        this.removeDislike(this.photos[this.swiper.activeIndex]);
    }

    private dislike() {
        this.addDislike(this.photos[this.swiper.activeIndex]);
        this.removeLike(this.photos[this.swiper.activeIndex]);
    }

    get isLiked() {
        return this.isPhotoLiked(this.activePhoto !== undefined ? this.activePhoto : this.initialPhoto);
    }

    get isDisliked() {
        return this.isPhotoDisliked(this.activePhoto !== undefined ? this.activePhoto : this.initialPhoto);
    }

    private toggleInfoModal() {
        this.$emit('toggleInfoModal');
    }

    private getImagePathByIndex(index) {
        return process.env.VUE_APP_BACKEND_URL + this.photos[index].path;
    }
}
