






















import { QuestionBase } from './QuestionBase';
import { Getter } from 'vuex-class';

export default class QuestionsDesktop extends QuestionBase {}
