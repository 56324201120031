import axios from 'axios';
import { Dimension } from '@/models/configurator/dimension';
import { ConfiguratorPrice } from '@/models/configurator/configuratorPrice';
import HttpException from './HttpException';

class DimensionWebservice {
    public async getAll() {
        try {
            const response = await axios.get<Dimension[]>('/configurator/v1/dimensions');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const dimensionWebservice = new DimensionWebservice();
