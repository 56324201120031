<template>
    <div class="home">
        <div class="columns">
            <div class="column is-6">
                <div class="card card-home equal-height">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">Voranalyse</p>
                                <p class="subtitle is-6">Welches Naturbad passt zu mir?</p>
                            </div>
                        </div>

                        <div class="content">
                            Mit unserem Voranalyse-Tool erhalten Sie die nötige Inspiration und finden innert Kürze
                            heraus, welcher Naturbad-Typ Sie tatsächlich sind.<br />
                            <br />
                            Machen Sie den Test und lassen Sie sich überraschen.
                        </div>
                    </div>
                    <footer class="card-footer">
                        <router-link tag="a" class="button is-primary is-medium" to="preanalysis"
                            >Voranalyse starten</router-link
                        >
                    </footer>
                </div>
            </div>

            <div class="column is-6">
                <div class="card card-home equal-height">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">Konfigurator</p>
                                <p class="subtitle is-6">Preis & Gestaltung</p>
                            </div>
                        </div>

                        <div class="content">
                            Falls Sie bereits wissen, welche Art Naturbad Sie für sich realisieren möchten, bieten wir
                            Ihnen die Möglichkeit, Ihr Traum-Naturbad selber zu konfigurieren. <br /><br />
                            Wählen Sie dabei einfach aus den Optionen Schwimmteich, Naturpool oder Biopool und
                            entscheiden Sie, welche Zusatzoptionen Sie noch benötigen. Der Naturbad-Konfigurator zeigt
                            Ihnen direkt auf, wie vielfältig Ihre Gestaltungsmöglichkeiten sind – stets mit
                            Visualisierung und ungefährer Preisangabe.
                        </div>
                    </div>
                    <footer class="card-footer">
                        <router-link class="button is-primary is-medium" to="configurator" tag="a"
                            >Konfigurator starten</router-link
                        >
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {},
};
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
}

.card-content {
    text-align: left;
}

.card-footer {
    justify-content: center;
    border: none;
    padding-bottom: 20px;
}

.equal-height {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.equal-height .card-content {
    flex: auto;
}

.media-content {
    overflow: hidden;
}
</style>
