
































































import axios from 'axios';
import router from 'vue-router';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/common/user';
import { userWebservice } from '@/webservices/common/user';
@Component({
    name: 'ResetPassword',
})
export default class ResetPassword extends Vue {
    // Data
    private user: User = {
        id: 0,
        role_id: 4,
        phone_number: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        new_password: '',
    };
    private disabled: boolean = false;
    private passwordChanged: boolean = false;
    private errorMsg: string = '';
    private token: string = '';

    private mounted() {
        if (this.$route.query.hasOwnProperty('token')) {
            this.token = this.$route.query.token.toString();
        }
    }

    // Methods
    private resetPassword() {
        if (this.user.new_password === '') {
            this.errorMsg = 'Bitte Passwort eingeben!';
            return 0;
        } else if (this.user.new_password.length < 8) {
            this.errorMsg = 'Das Passwort muss mindestens 8 Zeichen lang sein!';
            return 0;
        }
        this.disabled = true;
        this.errorMsg = '';

        userWebservice
            .resetPassword(this.token, this.user)
            .then((response) => {
                this.errorMsg = '';
                this.passwordChanged = true;
                setTimeout(() => {
                    this.$router.push({ path: '/login' });
                }, 3000);
            })
            .catch((error) => {
                this.disabled = false;
                this.errorMsg = 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut!';
                console.log(error);
            });
    }
}
