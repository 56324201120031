import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';

Vue.use(Vuex);

interface State {
    currentTab: number;
    laterGoToPhotos: boolean;
    laterGoToResults: boolean;
}

const mutations: MutationTree<State> = {
    setCurrentTab: (currentState, currentTab) => (currentState.currentTab = currentTab),
    setLaterGoToPhotos: (currentState, laterGoToPhotos) => (currentState.laterGoToPhotos = laterGoToPhotos),
    setLaterGoToResults: (currentState, laterGoToResults) => (currentState.laterGoToResults = laterGoToResults),
};

const actions: ActionTree<State, any> = {};

const getters = {
    currentTab(currentState: State) {
        return currentState.currentTab;
    },

    laterGoToPhotos(currentState: State) {
        return currentState.laterGoToPhotos;
    },

    laterGoToResults(currentState: State) {
        return currentState.laterGoToResults;
    },
};

const state: State = {
    currentTab: 0,
    laterGoToPhotos: false,
    laterGoToResults: false,
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
