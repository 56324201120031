import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { productWebservice } from '@/webservices/configurator/product';
import { Product } from '@/models/configurator/product';

Vue.use(Vuex);

interface State {
    products: Product[];
}

const mutations: MutationTree<State> = {
    setProducts: (currentState, products) => (currentState.products = products),

    resetState(currentState) {
        currentState.products = Array<Product>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchProducts({ commit }) {
        const products = await productWebservice.getAll();
        commit('setProducts', products);
    },
};

const getters = {
    products(currentState: State) {
        return currentState.products;
    },
};

const state: State = {
    products: Array<Product>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
