import axios from 'axios';
import { Topic } from '@/models/configurator/topic';
import HttpException from './HttpException';

class TopicWebservice {
    public async getAll(topicTypeName: string): Promise<Topic[]> {
        try {
            const response = await axios.get<Topic[]>('/configurator/v1/topics', {
                params: {
                    topic_type_name: topicTypeName,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const topicWebservice = new TopicWebservice();
