import axios from 'axios';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import HttpException from './HttpException';

class ConstructionMethodWebservice {
    public async getAll(): Promise<ConstructionMethod[]> {
        try {
            const response = await axios.get<ConstructionMethod[]>('/configurator/v1/construction_methods');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const constructionMethodWebservice = new ConstructionMethodWebservice();
