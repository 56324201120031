/* tslint:disable */
const de = {
    errors: {
        validation: {
            title: 'Bitte einen Titel eingeben',
            description: 'Bitte eine Beschreibung eingeben',
            name: 'Bitte einen Namen eingeben',
            email: 'Bitte eine gültige Email-Adresse eingeben',
            emailTaken: 'Diese E-Mail-Adresse wird schon verwendet',
            password: 'Bitte ein gültiges Passwort eingeben',
            passwordIsIncorrect: 'Das aktuell eingegebene Passwort ist falsch',
            currentPassword: 'Bitte das aktuelle Passwort eingeben',
            shortPassword: 'Das Passwort muss mindestens 8 Zeichen lang sein',
            firstName: 'Bitte den Vornamen eingeben',
            lastName: 'Bitte den Nachnamen eigeben',
            shortFirstNameOrLastName: 'Der Vorname / Nachname muss mindestens zwei Zeichen lang sein',
            abbreviation: 'Bitte eine Abkürzung eingeben',
            order: 'Bitte die Sortierung eingeben',
        },
        server: {
            invalidVerificationCode: 'Der Bestätigungscode ist nicht gültig!',
            invalidToken: 'Ungültiges Token',
        },
    },
    keywords: {
        yes: 'Ja',
        no: 'Nein',
        save: 'Speichern',
        update: 'Update',
        remove: 'Löschen',
        delete: 'Löschen',
        enable: 'Aktivieren',
        enabled: 'Aktiviert',
        disable: 'Deaktivieren',
        disabled: 'Deaktiviert',
        clear: 'Auswahl löschen',
        back: 'Zurück',
        cancel: 'Abbrechen',
        close: 'Schliessen',
        logout: 'Abmelden',
        login: 'Anmelden',
        register: 'Registrieren',
        title: 'Titel',
        account: 'Benutzerkonto',
        myAccount: 'Mein Konto',
        notification: 'Benachrichtigung | Benachrichtigungen',
        subscription: 'Abo',
        settings: 'Einstellungen',
        privacy: 'Privatssphäre',
        email: 'E-Mail',
        password: 'Passwort',
        new: 'Neue',
        newProject: 'Neues Projekt',
        description: 'Beschrieb',
        firstname: 'Vorname',
        lastname: 'Nachname',
        at: 'bei',
        actions: 'Aktionen',
        creator: 'Ersteller',
        search: 'Suche',
        send: 'Senden',
        confirm: 'Bestätigen',
        edit: 'Editieren',
        private: 'private',
        users: 'Benutzer',
        phone: 'Telefon',
        address: 'Adresse',
        additionalAddress: 'Zusätzliche Adresse',
        postcode: 'PLZ',
        place: 'Stadt',
        country: 'Land',
        order: 'Sortierung',
        abbreviation: 'Abkürzung',
        name: 'Name',
        income: 'Einkommen',
        perMonth: 'Per Monat',
        perYear: 'Per Jahr',
        all: 'Alle',
        question: 'Frage | Fragen',
    },
    labels: {
        // c
        currentPassword: 'Aktuelles Passwort',
        confirmEmail: 'Bestätigungs-E-Mail',
        // e
        editAccount: 'Konto bearbeiten',
        // n
        newPassword: 'Neues Passwort',
        //p
        passwordReset: 'Passwort zurücksetzen',
        passwordChange: 'Passwort ändern',

        // s
        saving: 'Speichern',
        send: 'Senden',

    },
    messages: {
        noChangesDetected: 'Keine Änderungen festgestellt!',
        changesSavedSuccessfully: 'Änderungen erfolgreich gespeichert.',
    },

    phrases: {
        // e
        emailConfirmation: 'Vielen Dank, die E-Mail-Adresse wurde bestätigt!',
        emailInstructionSending: 'Ein Email mit weiteren Instruktionen wurde an die folgende Email Adresse geschickt: ',
        entryPasswordRenewal: "Bitte ein neues Passwort eingeben",

         // f
         forwarding: 'Sie werden in Kürze weitergeleitet',

        // p
        passwordChangeSuccess: 'Das Passwort wurde erfolgreich geändert',
    }

};

export default de;