


























import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component
export default class GeneralBox extends Vue {}
