import axios from 'axios';
import HttpException from '@/webservices/configurator/HttpException';
import { Scope } from '@/models/common/scope';

class ScopeWebservice {
    public async getAll(): Promise<Scope[]> {
        try {
            const response = await axios.get<Scope[]>('/common/v1/scopes');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const scopeWebservice = new ScopeWebservice();
