import axios from 'axios';
import { Result as PAResult } from '@/models/preAnalysis/result';

class ResultWebservice {
    /**
     * Call the login method on api
     *
     * @param email Email address
     * @param result Result
     */
    public sendResult(pAResult: PAResult) {
        return axios.post('/pre_analysis/v1/results/store', {
            pAResult,
        });
    }
}

export const resultWebservice = new ResultWebservice();
