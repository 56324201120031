import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { User } from '@/models/common/user';
import axios from 'axios';

Vue.use(Vuex);

interface State {
    current_user: User;
    isLoggedIn: boolean;
}

const mutations: MutationTree<State> = {
    setCurrentUser: (currentState, currentUser) => (currentState.current_user = currentUser),
    setApiToken: (currentState, apiToken) => {
        // Update the local storage by hand
        localStorage.removeItem('api_token');
        localStorage.setItem('api_token', apiToken);
        axios.defaults.headers.common.Authorization = 'Bearer '.concat(apiToken);
        currentState.isLoggedIn = true;
    },

    logout: (currentState) => {
        currentState.isLoggedIn = false;
        (currentState.current_user = {
            id: 0,
            role_id: 0,
            phone_number: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            new_password: '',
        }),
            localStorage.removeItem('api_token');
        localStorage.clear();
    },

    resetState(currentState) {
        currentState.current_user = {
            id: 0,
            role_id: 0,
            phone_number: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            new_password: '',
        };
    },
};

const actions: ActionTree<State, any> = {};

const getters = {
    currentUser(currentState: State): User {
        return currentState.current_user;
    },
    isLoggedIn(currentState: State): boolean {
        return currentState.isLoggedIn;
    },
};

const state: State = {
    isLoggedIn: false,
    current_user: {
        id: 0,
        role_id: 0,
        phone_number: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        new_password: '',
    },
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
