
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'QuestionFinishedModal',
})
export default class QuestionFinishedModal extends Vue {
    @Prop() private isModalActive: boolean;

    private toggleModal() {
        this.$store.commit('preAnalysisWizard/setLaterGoToPhotos', true);
        this.$emit('toggleModal');
    }

    private goToPhotos() {
        this.$emit('goToPhotos');
    }
}
