import axios from 'axios';
import { Result } from '@/models/preAnalysis/result';

class ProposalWebservice {
    /**
     * Call the calculate proposal method on api
     *
     * @param result Result
     */
    public calculate(result: Result) {
        return axios.post('/pre_analysis/v1/proposals/calculate', {
            result,
        });
    }
}

export const proposalWebservice = new ProposalWebservice();
