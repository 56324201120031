import axios from 'axios';
import { Material } from '@/models/configurator/material';
import HttpException from './HttpException';

class MaterialWebservice {
    public async getAll() {
        try {
            const response = await axios.get<Material[]>('/configurator/v1/materials');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const materialWebservice = new MaterialWebservice();
