import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { formWebservice } from '@/webservices/configurator/form';
import { Form } from '@/models/configurator/form';

Vue.use(Vuex);

interface State {
    forms: Form[];
}

const mutations: MutationTree<State> = {
    // tslint:disable-next-line:max-line-length
    setForms: (currentState, forms) => (currentState.forms = forms),

    resetState(currentState) {
        currentState.forms = Array<Form>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchForms({ commit }) {
        try {
            const forms = await formWebservice.getAll();
            commit('setForms', forms);
        } catch (error) {
            console.error('Failed loading in the Webservice: [' + error.status + ']: ' + error.message);
            // Initialize the topics empty
            commit('setForms', []);
        }
    },
};

const getters = {
    forms(currentState: State) {
        return currentState.forms;
    },
};

const state: State = {
    forms: Array<Form>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
