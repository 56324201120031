/* tslint:disable */
import { Product } from '@/models/configurator/product';

export class AddonDataHelper {
    public getTopicImage(product: Product, topicTitle: string) {
        if (topicTitle === 'Holzterasse') {
            return [
                'img/reference/Zusatzausstattungen/03_Holzterrasse/DSC02231.jpg',
                'img/reference/Zusatzausstattungen/03_Holzterrasse/konfigurator-holzterrasse-kurze-seite.jpg',
                'img/reference/Zusatzausstattungen/03_Holzterrasse/konfigurator-holzterrasse-lange-seite-1.jpg',
                'img/reference/Zusatzausstattungen/03_Holzterrasse/konfigurator-holzterrasse.jpg',
            ];
        }

        if (topicTitle === 'Randabschluss') {
            if (product.name === 'schwimmteich') {
                return [
                    'img/reference/Zusatzausstattungen/03_Holzterrasse/DSC02231.jpg',
                    'img/reference/Zusatzausstattungen/03_Holzterrasse/konfigurator-holzterrasse-kurze-seite.jpg',
                    'img/reference/Zusatzausstattungen/03_Holzterrasse/konfigurator-holzterrasse-lange-seite-1.jpg',
                    'img/reference/Zusatzausstattungen/03_Holzterrasse/konfigurator-holzterrasse.jpg',
                ];
            }
        }

        if (topicTitle === 'Beleuchtung') {
            return [
                'img/reference/Zusatzausstattungen/05_Beleuchtung/DSC_0494.jpg',
                'img/reference/Zusatzausstattungen/05_Beleuchtung/konfigurator-beleuchtung-1.jpg',
                'img/reference/Zusatzausstattungen/05_Beleuchtung/konfigurator-beleuchtung-3.jpg',
            ];
        }
    }

    public getOptionImage(product: Product, optionText: string) {
        // RANDABSCHLUSS
        if (
            optionText === 'Kieschaussierung + Natursteinplatte' ||
            optionText === 'Natursteinplatten und Holzterasse kurze Seite'
        ) {
            return [
                // 'img/reference/Zusatzausstattungen/01_Randabschluss/01_Kieschaussierung/konfigurator-randabschluss-kieschaussierung.jpg',
                'img/reference/Zusatzausstattungen/01_Randabschluss/03_Natursteinplatten/24.07.2012035.jpg',
                'img/reference/Zusatzausstattungen/01_Randabschluss/03_Natursteinplatten/DSC07132.jpg',
                'img/reference/Zusatzausstattungen/01_Randabschluss/03_Natursteinplatten/DSC08638.jpg',
            ];
        }

        if (
            optionText === 'Kieschaussierung + Betonplatte' ||
            optionText === 'Betonplatten und Holzterrasse kurze Seite'
        ) {
            return [
                'img/reference/Zusatzausstattungen/01_Randabschluss/02_Betonplatten/DSC00425.jpg',
                'img/reference/Zusatzausstattungen/01_Randabschluss/02_Betonplatten/konfigurator-randabschluss-betonplatten-1.jpg',
            ];
        }

        // EINSTIEG
        if (optionText === 'Poolleiter aus Edelstahl') {
            return [
                'img/reference/Zusatzausstattungen/02_Einstieg/Poolleiter/konfigurator-poolleiter-edelstahl-1.jpg',
                'img/reference/Zusatzausstattungen/02_Einstieg/Poolleiter/konfigurator-poolleiter-edelstahl-3.jpg',
            ];
        }

        if (optionText === 'Einstiegtreppe seitlich') {
            return [
                'img/reference/Zusatzausstattungen/02_Einstieg/02_Treppeneinstieg_seitlich/IMG_2765.jpg',
                'img/reference/Zusatzausstattungen/02_Einstieg/02_Treppeneinstieg_seitlich/konfigurator-treppeneinstieg-seitlich-1.jpg',
            ];
        }

        if (optionText === 'Einstiegtreppe ganze Beckenbreite') {
            return [
                'img/reference/Zusatzausstattungen/02_Einstieg/03_Treppeneinstieg_ganze_Beckenbreite/konfigurator-treppeneinstieg-roemertreppe-1.jpg',
                'img/reference/Zusatzausstattungen/02_Einstieg/03_Treppeneinstieg_ganze_Beckenbreite/konfigurator-treppeneinstieg-roemertreppe-2.jpg',
                'img/reference/Zusatzausstattungen/02_Einstieg/03_Treppeneinstieg_ganze_Beckenbreite/UnterwasserfotosOktober2016.jpg',
            ];
        }

        // TECHNIK
        if (optionText === 'Pumpenschacht mit Druckfilter') {
            return [
                'img/reference/Zusatzausstattungen/04_Technik/Pumpenschacht_mit_Druckfilter/konfigurator-technik-skimmer-UVCmodul.jpg',
            ];
        }

        if (optionText === 'Skimmerinstallation') {
            return [
                'img/reference/Zusatzausstattungen/04_Technik/Skimmerinstallation/konfigurator-technik-skimmer-1.jpg',
                'img/reference/Zusatzausstattungen/04_Technik/Skimmerinstallation/konfigurator-technik-skimmer-2.jpg',
            ];
        }

        // WASSERATTRAKTION
        if (optionText === 'Quellstein') {
            return [
                'img/reference/Zusatzausstattungen/06_Wasserattraktion/01_Quellstein/konfigurator-wasserattraktion-quellstein.jpg',
            ];
        }

        if (optionText === 'Edelstahlspeier') {
            return [
                'img/reference/Zusatzausstattungen/06_Wasserattraktion/02_Wasserspeier/konfigurator-wasserattraktion-wasserspeier.jpg',
            ];
        }

        if (optionText === 'Pooltrainer') {
            return [
                'img/reference/Zusatzausstattungen/06_Wasserattraktion/03_Pooltrainer/konfigurator-wasserattraktion-pooltrainer-1.jpg',
                'img/reference/Zusatzausstattungen/06_Wasserattraktion/03_Pooltrainer/konfigurator-wasserattraktion-pooltrainer-2.jpg',
            ];
        }

        if (optionText === 'Gegenstromanlage Standard' || optionText === 'Gegenstromanlage Deluxe Turbine') {
            return ['img/reference/Zusatzausstattungen/06_Wasserattraktion/04_Gegenstromanlage/IMG_2766.jpg'];
        }

        if (optionText === 'Findlinge als Sitz- oder Liegestein') {
            return ['img/reference/Zusatzausstattungen/06_Wasserattraktion/Findlinge/bd_180527_DSC2380.jpg'];
        }

        if (
            optionText === 'Standard Edelstahldusche Kalt- und Warmwasser' ||
            optionText === 'Design Edelstahldusche Kalt- und Warmwasser'
        ) {
            return ['img/reference/Zusatzausstattungen/08_Dusche/konfigurator-edelstahldusche-1.jpg'];
        }

        // ABDECKUNG
        if (optionText === 'Manuelle Abdeckung mit Plane und Kurbel') {
            return [
                'img/reference/Zusatzausstattungen/07_Abdeckung/02_Manuelle_Abdeckung/konfigurator-abdeckung-manuell.jpg',
            ];
        }

        if (optionText === 'Automatische Abdeckung mit Rolladen Oberflur') {
            return [
                'img/reference/Zusatzausstattungen/07_Abdeckung/03_Automatische_Oberflur/bd_170602_DSC_4382.jpg',
                'img/reference/Zusatzausstattungen/07_Abdeckung/03_Automatische_Oberflur/konfigurator-automatische-abdeckung-oberflur.jpg',
            ];
        }

        if (optionText === 'Automatische Abdeckung mit Rolladen Unterflur') {
            return [
                'img/reference/Zusatzausstattungen/07_Abdeckung/04_Automatische_Unterflur/konfigurator-automatische-abdeckung-unterflur-0.jpg',
                'img/reference/Zusatzausstattungen/07_Abdeckung/04_Automatische_Unterflur/konfigurator-automatische-abdeckung-unterflur-1.jpg',
                'img/reference/Zusatzausstattungen/07_Abdeckung/04_Automatische_Unterflur/UnterwasserfotosOktober.jpg',
            ];
        }

        if (optionText === 'Zusätzlicher Chromstahlhandlauf - Ertrinkungsschutz') {
            return ['img/reference/Zusatzausstattungen/07_Abdeckung/Zusätzlicher/IMG_2769.jpg'];
        }

        // PFLEGE
        if (optionText === 'Automatischer Pflegeroboter') {
            return [
                'img/reference/Zusatzausstattungen/09_Pflege/AutomatischerPflegeroboter/konfigurator-pflege-dolphin.jpg',
                'img/reference/Zusatzausstattungen/09_Pflege/AutomatischerPflegeroboter/konfigurator-pflege-vortex-3.jpg',
            ];
        }
    }
}
