import { Component, Watch, Vue } from 'vue-property-decorator';

import { Getter } from 'vuex-class';

// Components
import QuestionBox from './QuestionBox.vue';
import EmailTheResults from '../followup/EmailTheResults.vue';
import QuestionsFinishedModal from './QuestionsFinishedModal.vue';
import { Option } from '@/models/preAnalysis/option';
import { Topic } from '@/models/preAnalysis/topic';

@Component({
    components: {
        // Include components
        QuestionBox,
        EmailTheResults,
        QuestionsFinishedModal,
    },
})
export class QuestionBase extends Vue {
    protected isModalActive: boolean = false;

    @Getter('preAnalysisTopic/topics') protected topics: Topic[];
    @Getter('preAnalysisWizard/laterGoToPhotos') protected laterGoToPhotos: boolean;
    @Getter('preAnalysisResult/selectedOptions') private selectedOptions: Option[];

    protected toggleModal() {
        this.isModalActive = !this.isModalActive;
    }

    protected goToPhotos() {
        // Check again if all questions are answered
        if (this.selectedOptions.length === this.topics.length) {
            this.$store.commit('preAnalysisWizard/setCurrentTab', 2);
        }
    }

    @Watch('selectedOptions')
    private selectedOptionsChanged() {
        this.isModalActive = this.allQuestionsAnswered();
    }

    private reset() {
        // Reset the result
        this.$store.commit('preAnalysisResult/reset');

        // Set the active topic to be the first one
        this.$store.commit('preAnalysisTopic/setActiveTopic', this.topics[0]);

        // Reset the go later to photos
        this.$store.commit('preAnalysisWizard/setLaterGoToPhotos', false);
        this.$store.commit('preAnalysisWizard/setLaterGoToResults', false);
    }

    private allQuestionsAnswered() {
        return this.selectedOptions.length === this.topics.length && !this.laterGoToPhotos;
    }
}
