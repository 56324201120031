









import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CreateTopicModal from './CreateTopicModal.vue';

@Component({
    name: 'CreateTopicModalButton',
    components: {
        CreateTopicModal,
    },
})
export default class CreateTopicModalButton extends Vue {
    private showModal: boolean = false;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    private toggleCreateTopicModal() {
        this.showModal = !this.showModal;
    }
}
