import { CreateOptionDto } from '@/models/editor/dto/CreateOptionDto';
import HttpException from '@/webservices/configurator/HttpException';
import { Option } from '@/models/configurator/option';
import axios from 'axios';
import { EditOptionDto } from '@/models/editor/dto/EditOptionDto';
import { DeleteOptionDto } from '@/models/editor/dto/DeleteOptionDto';
import { Topic } from '@/models/configurator/topic';

class EditorOptionWebservice {
    public async createOption(optionDto: CreateOptionDto): Promise<Option> {
        try {
            const url = '/editor/v1/topics/' + optionDto.topic_id + '/options';
            const response = await axios.post<Option>(url, optionDto);
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async saveOption(optionDto: EditOptionDto): Promise<Option> {
        try {
            const url = '/editor/v1/topics/' + optionDto.topic_id + '/options/' + optionDto.id;
            const response = await axios.put<Option>(url, optionDto);
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async saveOptionImages(optionDto: EditOptionDto, optionImages: FormData): Promise<Option> {
        try {
            const url = '/editor/v1/topics/' + optionDto.topic_id + '/options/' + optionDto.id + '/images';
            const response = await axios.post<Option>(url, optionImages, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async deleteOption(optionDto: DeleteOptionDto) {
        try {
            const url = '/editor/v1/topics/' + optionDto.topic_id + '/options/' + optionDto.id;
            const response = await axios.delete<Option>(url);
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }

    public async order(topic_id: number, options: Option[]): Promise<void> {
        try {
            const orders = options.map((option, index) => {
                return {
                    id: option.id,
                    order: index,
                };
            });
            const url = '/editor/v1/topics/' + topic_id + '/options/order';
            const response = await axios.post<void>(url, {
                orders,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong saving the data');
        }
    }
}

export const editorOptionWebservice = new EditorOptionWebservice();
