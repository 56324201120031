import { Option } from '@/models/configurator/option';
import { Result } from '@/models/configurator/result';

export class PriceHelper {
    private terrasseWidth: number = 2;

    public calculateOptionPrice(option: Option, result: Result) {
        // if price type is per Unit
        if (option.price_type_id === 1) {
            return this.getPrice(option, result) * option.value;

            // else if price type is per Meter of Perimeter
        } else if (option.price_type_id === 2) {
            // ===== SPECIAL CALCULATIONS =====
            // ABDECKUNG
            if (result.product.name === 'naturpool' || result.product.name === 'biopool') {
                if (option.id === 67 || option.id === 68 || option.id === 69) {
                    return result.dimension.width * this.getPrice(option, result);
                } else if (option.id === 70) {
                    return result.dimension.length * this.getPrice(option, result);
                }
            }

            return this.perimeter(result) * this.getPrice(option, result);

            // else if price type is per Square Meter of Area
        } else if (option.price_type_id === 3) {
            // ===== SPECIAL CALCULATIONS =====
            // RANDABSCHLUSS
            if (result.product.name === 'schwimmteich') {
                // SCHWIMMTEICH
                if (option.id === 48) {
                    return this.terrasseWidth * result.dimension.length * this.getPrice(option, result);
                } else if (option.id === 47) {
                    return this.terrasseWidth * result.dimension.width * this.getPrice(option, result);
                } else if (option.id === 49) {
                    return (
                        ((result.dimension.length + result.dimension.width) * this.terrasseWidth + 4) *
                        this.getPrice(option, result)
                    );
                } else if (option.id === 50) {
                    return this.getPrice(option, result) * option.value;
                }
            } else {
                // NATURPOOL + BIOPOOL
                if (option.id === 48) {
                    return this.terrasseWidth * result.dimension.length * this.getPrice(option, result);
                } else if (option.id === 47) {
                    return (this.terrasseWidth / 2.0) * result.dimension.width * this.getPrice(option, result);
                } else if (option.id === 49) {
                    return (
                        (result.dimension.width * (this.terrasseWidth / 2) +
                            result.dimension.length * this.terrasseWidth +
                            4) *
                        this.getPrice(option, result)
                    );
                } else if (option.id === 50) {
                    return this.getPrice(option, result) * option.value;
                }
            }

            // ABDECKUNG
            if (result.product.name === 'schwimmteich') {
                return this.area(result) * this.getPrice(option, result) * 1.3;
            }

            return this.area(result) * this.getPrice(option, result);
        } else {
            return 0;
        }
    }

    // calculate perimeter of pool
    protected perimeter(result: Result) {
        return (result.dimension.width + result.dimension.length) * 2;
    }

    // calculate area of pool
    protected area(result: Result) {
        return result.dimension.width * result.dimension.length;
    }

    private getPrice(option: Option, result: Result) {
        if (result.product.name === 'schwimmteich' && result.constructionMethod.name === 'diy') {
            return option.price[result.product.name + '_diy'];
        } else {
            return option.price[result.product.name];
        }
    }
}
