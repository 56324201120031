import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Topic } from '@/models/configurator/topic';
import { Option } from '@/models/configurator/option';
import { topicWebservice } from '@/webservices/configurator/topic';
import { editorTopicWebservice } from '@/webservices/editor/topic';
import { editorOptionWebservice } from '@/webservices/editor/option';

Vue.use(Vuex);

interface State {
    addonTopics: Topic[];
    activeTopic: Topic;
}

const mutations: MutationTree<State> = {
    setAddonTopics: (currentState, addonTopics) => (currentState.addonTopics = addonTopics),
    swapAddonTopics: (currentState, { sourceIndex, targetIndex }) => {
        const sourceTopic = currentState.addonTopics[sourceIndex];
        Vue.set(currentState.addonTopics, sourceIndex, currentState.addonTopics[targetIndex]);
        Vue.set(currentState.addonTopics, targetIndex, sourceTopic);
    },
    swapAddonTopicOptions: (currentState, { topic, sourceIndex, targetIndex }) => {
        const sourceOption = topic.options[sourceIndex];
        Vue.set(topic.options, sourceIndex, topic.options[targetIndex]);
        Vue.set(topic.options, targetIndex, sourceOption);
    },
    setAddonTopicOptions: (currentState, { topic, options }) => {
        topic.options = options;
    },
    setActiveTopic: (currentState, topic) => (currentState.activeTopic = topic),
    addTopic: (currentState, topic) => currentState.addonTopics.push(topic),
    updateTopic: (currentState, topic: Topic) => {
        const existingTopic = currentState.addonTopics.find((value) => value.id === topic.id);
        if (existingTopic) {
            existingTopic.title = topic.title;
            existingTopic.description = topic.description;
            existingTopic.required = topic.required;
            existingTopic.multianswer = topic.multianswer;
            existingTopic.published_at = topic.published_at;
            existingTopic.order = topic.order;
            existingTopic.scope_id = topic.scope_id;
            if (existingTopic.scope) {
                existingTopic.scope.id = topic.scope ? topic.scope.id : null;
                existingTopic.scope.name = topic.scope ? topic.scope.name : '';
            }
        }
    },
    removeTopic: (currentState, topic: Topic) => {
        const removeIndex = currentState.addonTopics.map((item) => item.id).indexOf(topic.id);
        if (removeIndex >= 0) {
            currentState.addonTopics.splice(removeIndex, 1);
        }
    },
    addOptionToTopic: (currentState, option: Option) => {
        const topic = currentState.addonTopics.find((value) => value.id === option.topic_id);
        if (topic) {
            topic.options.push(option);
        }
    },
    updateOptionInTopic: (currentState, option: Option) => {
        const topic = currentState.addonTopics.find((value) => value.id === option.topic_id);
        if (topic) {
            const existingOption = topic.options.find((value) => value.id === option.id);
            if (existingOption) {
                existingOption.text = option.text;
                existingOption.description = option.description;
                existingOption.price = option.price;
                existingOption.price_type_id = option.price_type_id;
                existingOption.price_type = option.price_type;
                existingOption.option_type_id = option.option_type_id;
                existingOption.option_type = option.option_type;
                existingOption.construction_methods = option.construction_methods;
                existingOption.products = option.products;
                existingOption.published_at = option.published_at;
                existingOption.scope_id = option.scope_id;
                if (existingOption.scope) {
                    existingOption.scope.id = option.scope ? option.scope.id : null;
                    existingOption.scope.name = option.scope ? option.scope.name : '';
                }
            }
        }
    },
    removeOptionInTopic: (currentState, option: Option) => {
        const topic = currentState.addonTopics.find((value) => value.id === option.topic_id);
        if (topic) {
            const removeIndex = topic.options.map((item) => item.id).indexOf(option.id);
            if (removeIndex >= 0) {
                topic.options.splice(removeIndex, 1);
            }
        }
    },
    resetState(currentState) {
        currentState.activeTopic = {
            id: 0,
            title: '',
            description: '',
            options: Array<Option>(),
            topic_type_id: 2,
            required: false,
            multianswer: false,
            published_at: new Date(),
            order: null,
            scope_id: null,
            scope: null,
            topic_images: [],
        };
    },
};

const actions: ActionTree<State, any> = {
    async fetchAddonTopics({ commit }) {
        try {
            let topics = await topicWebservice.getAll('configuratorAddon');
            topics = topics.sort((a: Topic, b: Topic): number => {
                if (a.order != null && b.order != null) {
                    return a.order - b.order;
                }
                return 0;
            });
            topics.forEach((topic) => {
                topic.options = topic.options.sort((a, b): number => {
                    if (a.order != null && b.order != null) {
                        return a.order - b.order;
                    }
                    return 0;
                });
            });
            commit('setAddonTopics', topics);
        } catch (error) {
            // Initialize the topics empty
            commit('setAddonTopics', []);
        }
    },

    async swapAddonTopics(context, { sourceIndex, targetIndex }) {
        const commit = context.commit;
        const currentState = context.state;
        commit('swapAddonTopics', {
            sourceIndex,
            targetIndex,
        });
        await editorTopicWebservice.order(currentState.addonTopics);
    },

    async setAddonTopics(context, topics: Topic[]) {
        const commit = context.commit;
        const currentState = context.state;
        commit('setAddonTopics', topics);
        await editorTopicWebservice.order(currentState.addonTopics);
    },

    async swapAddonTopicOptions(context, { topic_id, sourceIndex, targetIndex }) {
        const commit = context.commit;
        const currentState = context.state;
        const topic = currentState.addonTopics.find((item) => item.id === topic_id);
        if (topic) {
            commit('swapAddonTopicOptions', {
                topic,
                sourceIndex,
                targetIndex,
            });
            await editorOptionWebservice.order(topic_id, topic.options);
        }
    },

    async setAddonTopicOptions(context, { topic_id, options }) {
        const commit = context.commit;
        const currentState = context.state;
        const topic = currentState.addonTopics.find((item) => item.id === topic_id);
        if (topic) {
            commit('setAddonTopicOptions', {
                topic,
                options,
            });
            await editorOptionWebservice.order(topic_id, topic.options);
        }
    },

    async addAddonTopic({ commit }, topic: Topic) {
        commit('addTopic', topic);
    },

    async updateAddonTopic({ commit }, topic: Topic) {
        commit('updateTopic', topic);
        topic.options.forEach((option) => {
            commit('updateOptionInTopic', option);
        });
    },

    async removeAddonTopic({ commit }, topic: Topic) {
        commit('removeTopic', topic);
    },

    async addOptionToTopic({ commit }, option: Option) {
        commit('addOptionToTopic', option);
    },

    async updateOptionInTopic({ commit }, option: Option) {
        commit('updateOptionInTopic', option);
    },

    async removeOptionInTopic({ commit }, option: Option) {
        commit('removeOptionInTopic', option);
    },
};

const getters = {
    activeTopic(currentState: State) {
        return currentState.activeTopic;
    },

    addonTopics(currentState: State) {
        return currentState.addonTopics;
    },
};

const state: State = {
    addonTopics: Array<Topic>(),
    activeTopic: {
        id: 0,
        title: '',
        description: '',
        multianswer: false,
        required: false,
        options: Array<Option>(),
        topic_type_id: 2,
        published_at: new Date(),
        order: null,
        scope_id: null,
        scope: null,
        topic_images: [],
    },
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
