



































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import { Getter, Mutation } from 'vuex-class';

import { mapGetters } from 'vuex';
import { Photo } from '@/models/preAnalysis/photo';

import { photoWebservice } from '@/webservices/preAnalysis/photo';

import PhotoModal from './PhotoModal.vue';
import PhotoFinishedModal from './PhotoFinishedModal.vue';

import { Utils } from '@/helpers/Utils';

@Component({
    components: {
        PhotoModal,
        PhotoFinishedModal,
    },
})
export default class PhotoOverview extends Vue {
    protected isPhotoModalActive: boolean = false;
    protected isPhotoFinishedModalActive: boolean = false;

    protected activePhoto: Photo;
    protected activeIndex: number;
    protected utils: Utils = new Utils();

    // Vuex
    @Mutation('preAnalysisPhoto/setPhotosOverview') private setPhotosOverview;
    @Mutation('preAnalysisResult/removeLike') private removeLike;
    @Mutation('preAnalysisResult/removeDislike') private removeDislike;

    @Getter('preAnalysisPhoto/photosOverview') private photosOverview: Photo[];
    @Getter('preAnalysisResult/photosLiked') private photosLiked: Photo[];
    @Getter('preAnalysisResult/photosDisliked') private photosDisliked: Photo[];
    @Getter('preAnalysisResult/isPhotoLiked') private isPhotoLiked: boolean;
    @Getter('preAnalysisResult/isPhotoDisliked') private isPhotoDisliked: boolean;

    @Getter('preAnalysisWizard/laterGoToResults') private laterGoToResults: boolean;

    @Watch('photosLiked')
    private photosChanged() {
        if (this.photosLiked.length >= 3 && this.photosDisliked.length >= 3 && !this.laterGoToResults) {
            this.isPhotoFinishedModalActive = true;
        }

        // The user could be clicking later go to results and remove one of the likes
        // In this case we need to change the set later go to results variable so the message comes again
        if (this.laterGoToResults) {
            if (this.photosLiked.length < 3 || this.photosDisliked.length < 3) {
                this.$store.commit('preAnalysisWizard/setLaterGoToResults', false);
            }
        }
    }

    private mounted() {
        this.loadAllPhotos();
    }

    private loadAllPhotos() {
        if (this.photosOverview.length === 0) {
            photoWebservice
                .getAllPhotos()
                .then((photos) => {
                    photos = photos.filter((photo) => {
                        return photo.type === 'All';
                    });

                    photos = this.utils.shuffleArray(photos);

                    // Set the photos overview in the vuex store
                    this.setPhotosOverview(photos);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    private togglePhotoModal(photo: Photo, index: number) {
        // Toggle the status of the modal view
        this.isPhotoModalActive = !this.isPhotoModalActive;

        if (photo !== undefined) {
            // Set the current active photo
            this.activePhoto = photo;
        }

        if (index !== undefined) {
            this.activeIndex = index;
        }
    }

    private togglePhotoFinishedModal() {
        this.isPhotoFinishedModalActive = !this.isPhotoFinishedModalActive;
    }

    private reset() {
        // Reset the result
        this.$store.commit('preAnalysisResult/reset');

        // Reset the go later to photos/results
        this.$store.commit('preAnalysisWizard/setLaterGoToPhotos', false);
        this.$store.commit('preAnalysisWizard/setLaterGoToResults', false);

        // Switch the tab to the beginning
        this.$store.commit('preAnalysisWizard/setCurrentTab', 1);
    }

    private goToResults() {
        if (this.photosLiked.length >= 3 && this.photosDisliked.length >= 3) {
            this.$store.commit('preAnalysisWizard/setCurrentTab', 3);
        }
    }
}
