















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import DetailModal from './DetailModal.vue';

@Component({
    name: 'DetailModalButton',
    components: {
        DetailModal,
    },
})
export default class DetailModalButton extends Vue {
    private showModal: boolean = false;

    @Prop() private title: string;
    @Prop() private photos: string[];
    @Prop() private imagesOnly: boolean;

    private toggleDetailModal() {
        this.showModal = !this.showModal;
    }
}
