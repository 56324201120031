






















































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CreateOptionDto } from '@/models/editor/dto/CreateOptionDto';
import { Topic } from '@/models/configurator/topic';
import { Product } from '@/models/configurator/product';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { OptionType } from '@/models/configurator/optionType';
import { PriceType } from '@/models/configurator/priceType';
import Multiselect from 'vue-multiselect';
import { ProductType } from '@/models/common/enums/ProductType.ts';
import { ConstructionMethodType } from '@/models/common/enums/ConstructionMethodType';
import { Scope } from '@/models/common/scope';
import { EditOptionDto } from '@/models/editor/dto/EditOptionDto';
import UploadImages from '@/components/prine/UploadImages.vue';
import { OptionImage } from '@/models/configurator/optionImage';

@Component({
    name: 'OptionForm',
    components: {
        Multiselect,
        UploadImages,
    },
})
export default class OptionForm extends Vue {
    @Getter('configuratorProduct/products') private products: Product[];
    @Getter('configuratorConstructionMethod/constructionMethods') private constructionMethods: ConstructionMethod[];
    @Getter('editorOptionType/optionTypes') private optionTypes: OptionType[];
    @Getter('editorPriceType/priceTypes') private priceTypes: PriceType[];
    @Getter('scope/scopes') private scopes: Scope[];

    @Action('editorOptionType/fetchOptionTypes') private fetchOptionTypes;
    @Action('editorPriceType/fetchPriceTypes') private fetchPriceTypes;

    @Prop()
    private topic: Topic;
    @Prop()
    private option: CreateOptionDto | EditOptionDto;
    @Prop()
    private title: string;
    @Prop()
    private isTopicScopeIntern: boolean;
    private optionDto: CreateOptionDto | EditOptionDto = JSON.parse(JSON.stringify(this.option)) as CreateOptionDto;
    private selectedProducts: Product[] = [];
    private selectedCM: ConstructionMethod[] = [];
    private selectedOptionType: OptionType | null = null;
    private selectedPriceType: PriceType | null = null;
    private selectedScope: Scope | null = null;
    private validated: boolean = false;
    private saving: boolean = false;
    private images_file: File[] = [];

    private mounted() {
        this.fetchOptionTypes();
        this.fetchPriceTypes();
        if (this.optionDto.product_ids.length > 0) {
            this.selectedProducts = this.products.filter((product) => {
                return this.optionDto.product_ids.indexOf(product.id) > -1;
            });
        }
        if (this.optionDto.construction_method_ids.length > 0) {
            this.selectedCM = this.constructionMethods.filter((cm) => {
                return this.optionDto.construction_method_ids.indexOf(cm.id) > -1;
            });
        }
        const optionType = this.optionTypes.find((item) => {
            return this.optionDto.option_type_id === item.id;
        });
        if (optionType) {
            this.selectedOptionType = optionType;
        } else {
            this.selectedOptionType = null;
        }
        const priceType = this.priceTypes.find((item) => {
            return this.optionDto.price_type_id === item.id;
        });
        if (priceType) {
            this.selectedPriceType = priceType;
        } else {
            this.selectedPriceType = null;
        }
        const scope = this.scopes.find((item) => {
            return this.optionDto.scope_id === item.id;
        });
        if (scope) {
            this.selectedScope = scope;
        } else {
            this.selectedScope = null;
        }
        this.setUnselectedPrices();
    }

    private toggleModal() {
        this.$emit('toggleModal');
    }

    private saveOption() {
        if (this.validate()) {
            const clonedOption = JSON.parse(JSON.stringify(this.optionDto));
            this.setUnselectedPrices(0, clonedOption);
            this.saving = true;
            this.$emit('update:images_file', this.images_file);
            this.$emit('saveValidatedOption', clonedOption);
        }
    }

    private validate() {
        const isTitleValid = (this.$refs.titleField as any).checkHtml5Validity();
        const isBiopoolPriceValid = (this.$refs.biopoolPriceField as any).checkHtml5Validity();
        const isNaturpoolPriceValid = (this.$refs.naturpoolPriceField as any).checkHtml5Validity();
        const isSchwimmteichProfiPriceValid = (this.$refs.schwimmteichProfiPriceField as any).checkHtml5Validity();
        const isSchwimmteichDIYPriceValid = (this.$refs.schwimmteichDIYPriceField as any).checkHtml5Validity();
        this.validated = true;
        return (
            isTitleValid &&
            isBiopoolPriceValid &&
            isNaturpoolPriceValid &&
            isSchwimmteichProfiPriceValid &&
            isSchwimmteichDIYPriceValid &&
            this.isSelectedProductsValid &&
            this.isSelectedCMsValid &&
            this.isOptionTypeValid &&
            this.isPriceTypeValid &&
            this.isScopeValid
        );
    }

    private changed() {
        this.$emit('changed');
    }

    private async setUnselectedPrices(value: any = '', option = this.optionDto) {
        if (!this.isBioPoolSelected) {
            option.price.biopool = value;
        }
        if (!this.isNaturPoolSelected) {
            option.price.naturpool = value;
        }
        if (!this.isSchwimmteichAndProfiSelected) {
            option.price.schwimmteich = value;
        }
        if (!this.isSchwimmteichAndDIYSelected) {
            option.price.schwimmteich_diy = value;
        }
        this.$forceUpdate();
        await this.$nextTick();
        this.resetPriceFieldErrors();
    }

    private resetPriceFieldErrors() {
        if (!this.isBioPoolSelected) {
            (this.$refs.biopoolPriceField as any).checkHtml5Validity();
        }
        if (!this.isNaturPoolSelected) {
            (this.$refs.naturpoolPriceField as any).checkHtml5Validity();
        }
        if (!this.isSchwimmteichAndProfiSelected) {
            (this.$refs.schwimmteichProfiPriceField as any).checkHtml5Validity();
        }
        if (!this.isSchwimmteichAndDIYSelected) {
            (this.$refs.schwimmteichDIYPriceField as any).checkHtml5Validity();
        }
    }

    private updateSelectedProducts() {
        this.changed();
        this.setUnselectedPrices();
        this.optionDto.product_ids = this.selectedProducts.map((product) => product.id);
        if (!this.isSchwimmteichSelected && this.selectedCM.length > 0) {
            this.selectedCM = [];
            this.updateSelectedCMs();
        }
    }

    private updateSelectedCMs() {
        this.changed();
        this.setUnselectedPrices();
        this.optionDto.construction_method_ids = this.selectedCM.map((cm) => cm.id);
    }

    private updateOptionType() {
        this.changed();
        if (this.selectedOptionType) {
            this.optionDto.option_type_id = this.selectedOptionType.id;
        } else {
            this.optionDto.option_type_id = null;
        }
    }

    private updatePriceType() {
        this.changed();
        if (this.selectedPriceType) {
            this.optionDto.price_type_id = this.selectedPriceType.id;
        } else {
            this.optionDto.price_type_id = null;
        }
    }

    private updateScope() {
        this.changed();
        if (this.selectedScope) {
            this.optionDto.scope_id = this.selectedScope.id;
        } else {
            this.optionDto.scope_id = null;
        }
    }

    get isNaturPoolSelected() {
        return this.selectedProducts.some((product) => product.name === ProductType.Naturpool);
    }

    get isBioPoolSelected() {
        return this.selectedProducts.some((product) => product.name === ProductType.Biopool);
    }

    get isSchwimmteichSelected() {
        return this.selectedProducts.some((product) => product.name === ProductType.Schwimmteich);
    }

    get isSchwimmteichAndProfiSelected() {
        return (
            this.selectedProducts.some((product) => product.name === ProductType.Schwimmteich) &&
            this.selectedCM.some((cm) => cm.name === ConstructionMethodType.Profi)
        );
    }

    get isSchwimmteichAndDIYSelected() {
        return (
            this.selectedProducts.some((product) => product.name === ProductType.Schwimmteich) &&
            this.selectedCM.some((cm) => cm.name === ConstructionMethodType.DIY)
        );
    }

    get isOptionTypeValid() {
        return this.optionDto.option_type_id != null;
    }

    get isPriceTypeValid() {
        return this.optionDto.price_type_id != null;
    }

    get isSelectedProductsValid() {
        return this.selectedProducts.length > 0;
    }

    get isSelectedCMsValid() {
        if (!this.isSchwimmteichSelected) {
            return true;
        } else {
            return this.selectedCM.length > 0;
        }
    }

    get isScopeValid() {
        return this.optionDto.scope_id != null;
    }

    get option_images_path() {
        return this.optionDto.option_images?.map((el: OptionImage) => process.env.VUE_APP_BACKEND_URL + el.path);
    }

    private deleteOptionImages() {
        this.optionDto.option_images = [];
    }
}
