import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { PriceType } from '@/models/configurator/priceType';
import { priceTypeWebservice } from '@/webservices/editor/priceType';

Vue.use(Vuex);

interface State {
    priceTypes: PriceType[];
}

const mutations: MutationTree<State> = {
    setPriceTypes: (currentState, priceTypes) => (currentState.priceTypes = priceTypes),

    resetState(currentState) {
        currentState.priceTypes = Array<PriceType>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchPriceTypes({ commit }) {
        const priceTypes = await priceTypeWebservice.getAll();
        commit('setPriceTypes', priceTypes);
    },
};

const getters = {
    priceTypes(currentState: State) {
        return currentState.priceTypes;
    },
};

const state: State = {
    priceTypes: Array<PriceType>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
