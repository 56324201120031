














import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';

@Component({
    name: 'ResetButton',
    components: {
        ConfirmModal,
    },
})
export default class ResetButton extends Vue {
    private isModalActive: boolean = false;

    @Action('utils/RESET_CONFIGURATOR') private reset;

    private toggleConfirmModal(answer: boolean) {
        this.isModalActive = !this.isModalActive;
        if (answer === true) {
            this.reset();

            // Push url without params
            this.$router.push({
                path: this.$router.currentRoute.path,
            });
        }
    }
}
