import axios from 'axios';
import { VisualizationBasic } from '@/models/configurator/visualizationBasic';
import HttpException from './HttpException';

class VisualizationWebservice {
    public async getAll(): Promise<VisualizationBasic[]> {
        try {
            const response = await axios.get<VisualizationBasic[]>('/configurator/v1/visualizations/basic');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const visualizationWebservice = new VisualizationWebservice();
