import { StoreOption } from './StoreOption';
import { Topic } from '@/models/configurator/topic';
import { Option } from '@/models/configurator/option';
import { Store } from 'vuex';

export class StoreOptionRadio implements StoreOption {
    public optionType: string;

    constructor() {
        this.optionType = 'radio';
    }

    public store(topic: Topic, option: Option, target: any, store: Store<any>) {
        // Iterate and replace or add it
        const index = topic.options.findIndex((o) => {
            return o.id === option.id;
        });

        if (index !== -1) {
            // If it exists, remove it
            topic.options = [];
        } else {
            // If not add it
            topic.options = [option];
        }

        store.commit('configuratorResult/setSelectedTopic', topic);
    }
}
