













import axios from 'axios';
import router from 'vue-router';
import { User } from '../../../models/common/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userWebservice } from '../../../webservices/common/user';
import { Getter } from 'vuex-class';

@Component({
    name: 'Logout',
})
export default class Logout extends Vue {
    // Data
    private user: User = {
        id: 0,
        role_id: 4,
        phone_number: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        new_password: '',
    };

    @Getter('account/currentUser') private current_user: User;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    private mounted() {
        this.logout();
    }

    // Methods
    private logout() {
        userWebservice
            .logout()
            .then((response) => {
                this.$store.commit('account/logout');
                setTimeout(() => {
                    this.$router.push({ path: '/' });
                }, 600);
            })
            .catch(function(error) {
                console.log(error);
            });
    }
}
