












































































































import { User } from '@/models/common/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { userWebservice } from '@/webservices/common/user';

@Component({
    name: 'Edit',
})
export default class Edit extends Vue {
    private updatingUserInfoErrorMsg: string = '';
    private changePassword: boolean = false;
    private updatingUserInfo: boolean = false;

    private user: User = {} as User;
    private userOld: User = {} as User;

    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    @Getter('account/currentUser') private current_user: User;
    // Mounted is called initially
    private mounted() {
        // deep copy the current user object
        this.user = JSON.parse(JSON.stringify(this.current_user));
        this.user.password = '';
        this.user.new_password = '';
        this.userOld = JSON.parse(JSON.stringify(this.user));
    }

    private save() {
        this.updatingUserInfo = true;
        if (!this.validate()) {
            this.updatingUserInfo = false;
            return false;
        }

        // Update
        userWebservice
            .update(this.user)
            .then((response) => {
                this.updatingUserInfo = false;
                this.updatingUserInfoErrorMsg = '';
                this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('messages.changesSavedSuccessfully').toString(),
                    type: 'is-success',
                });
                this.user.new_password = '';
                this.user.password = '';
                this.updateUserInfo();
                this.userOld = JSON.parse(JSON.stringify(this.user));
            })
            .catch((error) => {
                this.updatingUserInfo = false;
                if (error.response.data.current_password) {
                    this.updatingUserInfoErrorMsg = this.$t('errors.validation.password').toString();
                }
                if (error.response.data.errorMsg && error.response.data.errorMsg === 'email taken') {
                    this.updatingUserInfoErrorMsg = this.$t('errors.validation.emailTaken').toString();
                }

                if (error.response.data.errorMsg && error.response.data.errorMsg === 'incorrectPassword') {
                    this.updatingUserInfoErrorMsg = this.$t('errors.validation.passwordIsIncorrect').toString();
                }
            });
    }

    private validate() {
        // tslint:disable-next-line:max-line-length
        if (
            this.user.firstname !== this.userOld.firstname ||
            this.user.lastname !== this.userOld.lastname ||
            this.user.email !== this.userOld.email ||
            this.user.new_password !== ''
        ) {
            if (this.user.password === '') {
                this.updatingUserInfoErrorMsg = this.$t('errors.validation.currentPassword').toString();
                return false;
            } else if (this.user.firstname === '') {
                this.updatingUserInfoErrorMsg = this.$t('errors.validation.firstName').toString();
                return false;
            } else if (this.user.lastname === '') {
                this.updatingUserInfoErrorMsg = this.$t('errors.validation.lastName').toString();
                return false;
            } else if (this.user.firstname.length < 2 || this.user.lastname.length < 2) {
                this.updatingUserInfoErrorMsg = this.$t('errors.validation.shortFirstNameOrLastName').toString();
                return false;
            } else if (!this.validateEmail(this.user.email)) {
                this.updatingUserInfoErrorMsg = this.$t('errors.validation.email').toString();
                return false;
            } else if (this.user.new_password !== '') {
                if (this.user.password === '') {
                    this.updatingUserInfoErrorMsg = this.$t('errors.validation.currentPassword').toString();
                    return false;
                } else if (this.user.password.length < 8) {
                    this.updatingUserInfoErrorMsg = this.$t('errors.validation.password').toString();
                    return false;
                } else if (this.user.new_password.length < 8) {
                    this.updatingUserInfoErrorMsg = this.$t('errors.validation.shortPassword').toString();
                    return false;
                }
            }
            return true;
        } else {
            // return false because there is no change
            this.$buefy.toast.open({
                duration: 3000,
                message: this.$t('messages.noChangesDetected').toString(),
                type: 'is-warning',
            });
            return false;
        }
    }

    private validateEmail(email) {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    private updateUserInfo() {
        userWebservice.getUserInfo().then((response) => {
            this.$store.commit('account/setCurrentUser', response.data);
        });
    }
}
