

















































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { mapGetters } from 'vuex';

import { proposalWebservice } from '@/webservices/preAnalysis/proposal';
import { photoWebservice } from '@/webservices/preAnalysis/photo';

import { ScreenHelper } from '@/helpers/ScreenHelper';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';
import { Photo } from '@/models/preAnalysis/photo';

import ProposalModal from './ProposalModal.vue';
import { Result } from '@/models/preAnalysis/result';
import { Option } from '@/models/preAnalysis/option';

// this should remain here , in case of removal browser will throw an error about not defined Getters :|
@Component({
    components: {
        ProposalModal,
    },
})
export default class Proposal extends Vue {
    // Data
    protected loading: boolean = false;
    protected currentImage: number = 1;
    protected screenHelper: ScreenHelper = new ScreenHelper();
    protected isModalActive: boolean = false;
    protected activeProposal: ProposalMatch = {
        title: '',
        text: '',
        match_sum: 0,
        match_percentage: 0,
        proposal_id: 0,
    };

    protected swiperOption = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        speed: 600,
        parallax: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    private maxPercentagePhotos = 33;

    // Vuex
    @Getter('preAnalysisResult/result') private result: Result;
    @Getter('preAnalysisResult/selectedOptions') private selectedOptions: Option[];
    @Getter('preAnalysisResult/values') private values: number[];
    @Getter('preAnalysisResult/photosLiked') private photosLiked: Photo[];
    @Getter('preAnalysisResult/photosDisliked') private photosDisliked: Photo[];
    @Getter('preAnalysisResult/proposalMatches') private proposalMatches: ProposalMatch[];
    @Getter('preAnalysisResult/amountOfLikes') private amountOfLikes: number;
    @Getter('preAnalysisResult/amountOfDislikes') private amountOfDislikes: number;
    @Getter('preAnalysisPhoto/coverPhoto') private coverPhoto: Photo;

    @Mutation('preAnalysisPhoto/setPhotos') private setPhotos;
    @Mutation('preAnalysisResult/setProposalMatches') private setProposalMatches;

    @Prop() private minimal: boolean;

    @Watch('values')
    @Watch('photosLiked')
    @Watch('photosDisliked')
    private onValuesChanged() {
        this.calculate();
    }

    // Mounted is called initially
    private mounted() {
        this.calculate();

        this.screenHelper.detectScreenSize();
        this.activeProposal = this.proposalMatches[0];

        // For the proposals we need to load the photos
        this.loadPhotos();
    }

    private loadPhotos() {
        photoWebservice
            .getAllPhotos()
            .then((photos) => {
                // Set the photos in the vuex store
                this.setPhotos(photos);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private toggleInfoModal(proposalMatch: ProposalMatch) {
        this.isModalActive = !this.isModalActive;
        if (proposalMatch !== null) {
            this.activeProposal = proposalMatch;
        }
    }

    private calculate() {
        if (this.selectedOptions.length > 0) {
            proposalWebservice.calculate(this.result).then((response) => {
                this.setProposalMatches(response.data);

                // Also include the photos and adjust the percentage match by 5% (+/-) depending on like/dislike
                this.calculatePhotoSelection();

                this.proposalMatches.sort((b, a) => {
                    return a.match_percentage - b.match_percentage;
                });
            });
        }
    }

    private calculatePhotoSelection() {
        // Calculate the percentage per photo (max is normally 20% which can be given away).
        // The more likes/dislikes there are the smaller is the percentage value it adapts
        // tslint:disable-next-line:max-line-length
        const percentagePerPhoto = this.maxPercentagePhotos / (this.photosLiked.length + this.photosDisliked.length);

        this.proposalMatches.map((proposalMatch: ProposalMatch) => {
            // If its a liked photo which belongs to this proposal, we need to increase the % of the match
            this.photosLiked.filter((photo: Photo) => {
                if (photo.proposal_id === proposalMatch.proposal_id) {
                    proposalMatch.match_percentage += percentagePerPhoto;

                    // So it can't go over 100
                    // tslint:disable-next-line:max-line-length
                    proposalMatch.match_percentage =
                        proposalMatch.match_percentage >= 100 ? 100 : proposalMatch.match_percentage;
                }
            });

            // If its a disliked photo which belongs to this proposal, we need to increase the % of the match
            this.photosDisliked.filter(function(photo: Photo) {
                if (photo.proposal_id === proposalMatch.proposal_id) {
                    proposalMatch.match_percentage -= percentagePerPhoto;

                    // So it can't go over 100
                    // tslint:disable-next-line:max-line-length
                    proposalMatch.match_percentage =
                        proposalMatch.match_percentage >= 100 ? 100 : proposalMatch.match_percentage;
                }
            });

            proposalMatch.match_percentage = Math.round(proposalMatch.match_percentage);
        });
    }

    private countProposalIsLiked(proposalMatch: ProposalMatch) {
        return this.photosLiked.filter((likedPhoto) => {
            return likedPhoto.proposal_id === proposalMatch.proposal_id;
        }).length;
    }

    private countProposalIsDisliked(proposalMatch: ProposalMatch) {
        return this.photosDisliked.filter((dislikedPhoto) => {
            return dislikedPhoto.id === proposalMatch.proposal_id;
        }).length;
    }
}
