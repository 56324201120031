import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Result } from '@/models/preAnalysis/result';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';
import { Option } from '@/models/preAnalysis/option';
import { Photo } from '@/models/preAnalysis/photo';
import axios from 'axios';

Vue.use(Vuex);

interface State {
    result: Result;
    values: number[];
    proposalMatches: ProposalMatch[];
}

const mutations: MutationTree<State> = {
    setResult: (currentState, result) => {
        currentState.result = result;
    },

    setValues: (currentState, values) => {
        currentState.values = values;
    },

    setProposalMatches: (currentState, values) => {
        currentState.result.proposal_matches = values;
        currentState.proposalMatches = values;
    },

    addOption: (currentState, data) => {
        const newOption: Option = data.option;
        const topic_id = data.topic_id;

        let index = -1;

        // Find the index of the existing answer
        for (let i = 0; i < currentState.result.options.length; i++) {
            if (currentState.result.options[i].topic_id === topic_id) {
                index = i;
                break;
            }
        }

        newOption.topic_id = topic_id;

        if (index !== -1) {
            // It does already exist, replace this answer
            // state.result.options[index] = newOption
            Vue.set(currentState.result.options, index, newOption);
        } else {
            // state.result.options.push(newOption)
            Vue.set(currentState.result.options, currentState.result.options.length, newOption);
        }

        // Update the values for the graph
        // IMPORTANT: Be careful here, we expect that the Options are always
        // generated from a given seeder file with the same order
        const invertedValue = Math.abs(newOption.value - 4) + 1;
        currentState.values[newOption.topic_id - 1] = invertedValue;

        // a hack to make a change in state so the vue watchers notice the change !
        const tempValues = currentState.values;
        currentState.values = [];
        currentState.values = tempValues;
    },

    addLike(currentState: State, photo: Photo) {
        if (currentState.result.photos_liked.indexOf(photo) === -1) {
            currentState.result.photos_liked.push(photo);
        } else {
            // When it already exist remove it
            currentState.result.photos_liked = currentState.result.photos_liked.filter(function(photoLiked) {
                return photoLiked.id !== photo.id;
            });
        }
    },

    removeLike(currentState: State, photo: Photo) {
        currentState.result.photos_liked = currentState.result.photos_liked.filter(function(photoLiked) {
            return photoLiked.id !== photo.id;
        });
    },

    addDislike(currentState: State, photo: Photo) {
        if (currentState.result.photos_disliked.indexOf(photo) === -1) {
            currentState.result.photos_disliked.push(photo);
        } else {
            // When it alreay exist remove it
            currentState.result.photos_disliked = currentState.result.photos_disliked.filter(function(photoDisliked) {
                return photoDisliked.id !== photo.id;
            });
        }
    },

    removeDislike(currentState: State, photo: Photo) {
        currentState.result.photos_disliked = currentState.result.photos_disliked.filter(function(photoDisliked) {
            return photoDisliked.id !== photo.id;
        });
    },

    reset(currentState) {
        console.log('RESET the whole result object');

        currentState.result = {
            id: 0,
            when: new Date(),
            firstname: '',
            lastname: '',
            phone_number: '',
            email: '',
            follow_up: false,
            comment: '',
            file_name: '',
            options: Array<Option>(),
            photos_liked: Array<Photo>(),
            photos_disliked: Array<Photo>(),
            proposal_matches: Array<ProposalMatch>(),
        };

        currentState.values = [0, 0, 0, 0, 0, 0, 0, 0];
        currentState.proposalMatches = [];
    },
};

const actions: ActionTree<State, any> = {};

const getters = {
    values(currentState: State): number[] {
        return currentState.values;
    },

    proposalMatches(currentState: State): ProposalMatch[] {
        return currentState.proposalMatches;
    },

    result(currentState: State): Result {
        return currentState.result;
    },

    selectedOptions(currentState: State): Option[] {
        return currentState.result.options;
    },

    photosLiked(currentState: State): Photo[] {
        return currentState.result.photos_liked;
    },

    photosDisliked(currentState: State): Photo[] {
        return currentState.result.photos_disliked;
    },

    isPhotoLiked: (currentState: State) => (photo: Photo) => {
        return (
            currentState.result.photos_liked.filter((likedPhoto) => {
                return likedPhoto.id === photo.id;
            }).length === 1
        );
    },

    isPhotoDisliked: (currentState: State) => (photo: Photo) => {
        return (
            currentState.result.photos_disliked.filter((dislikedPhoto) => {
                return dislikedPhoto.id === photo.id;
            }).length === 1
        );
    },

    amountOfLikes: (currentState: State) => (proposalMatch: ProposalMatch) => {
        return currentState.result.photos_liked.filter((likedPhoto) => {
            return likedPhoto.proposal_id === proposalMatch.proposal_id;
        }).length;
    },

    amountOfDislikes: (currentState: State) => (proposalMatch: ProposalMatch) => {
        return currentState.result.photos_disliked.filter((dislikedPhoto) => {
            return dislikedPhoto.proposal_id === proposalMatch.proposal_id;
        }).length;
    },
};

const state: State = {
    result: {
        id: 0,
        when: new Date(),
        firstname: '',
        lastname: '',
        phone_number: '',
        email: '',
        follow_up: false,
        comment: '',
        file_name: '',
        options: Array<Option>(),
        photos_liked: Array<Photo>(),
        photos_disliked: Array<Photo>(),
        proposal_matches: Array<ProposalMatch>(),
    },

    values: [0, 0, 0, 0, 0, 0, 0, 0],

    proposalMatches: Array<ProposalMatch>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
