

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import OfferSelection from '@/components/configurator/offer/OfferSelection.vue';
import OfferAddons from '@/components/configurator/offer/OfferAddons.vue';

@Component({
    name: 'OfferModal',
    components: {
        OfferSelection,
        OfferAddons,
    },
})
export default class OfferModal extends Vue {
    private toggleModal() {
        this.$emit('toggleModal');
    }
}
