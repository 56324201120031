import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import WizardPreAnalysis from '../components/preAnalysis/wizard/WizardPreAnalysis.vue';
import WizardConfigurator from '../components/configurator/wizard/WizardConfigurator.vue';
import Login from '../components/common/auth/Login.vue';
import Profile from '../components/common/auth/Profile.vue';
import Logout from '../components/common/auth/Logout.vue';
import ResetPassword from '../components/common/auth/ResetPassword.vue';
import ForgotPassword from '../components/common/auth/ForgotPassword.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },

    {
        path: '/preanalysis',
        name: 'WizardPreAnalysis',
        component: WizardPreAnalysis,
    },

    {
        path: '/configurator',
        name: 'WizardConfigurator',
        component: WizardConfigurator,
    },

    {
        path: '/login',
        name: 'Login',
        component: Login,
    },

    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },

    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },

    {
        path: '/resetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
    },

    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
