






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { mapGetters } from 'vuex';

import { topicWebservice } from '@/webservices/preAnalysis/topic';
import { Topic } from '@/models/preAnalysis/topic';

import EmailTheResults from './followup/EmailTheResults.vue';
import Proposal from './proposal/Proposal.vue';
import PhotoSelection from './photos/PhotoSelection.vue';
import PhotoOverview from './photos/PhotoOverview.vue';

// ScreenHelper
import { ScreenHelper } from '@/helpers/ScreenHelper';

@Component({
    components: {
        EmailTheResults,
        Proposal,
        PhotoOverview,
        PhotoSelection,
    },
})
export default class AnalysisPhotos extends Vue {
    // Data
    protected screenHelper: ScreenHelper = new ScreenHelper();

    // Mounted is called initially
    private mounted() {
        this.screenHelper.detectScreenSize();
    }
}
