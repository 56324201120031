import Vue from 'vue';
import Vuex, { ActionTree, MutationTree } from 'vuex';
import { Topic } from '@/models/configurator/topic';
import { editorTopicWebservice } from '@/webservices/editor/topic';
import { CreateTopicDto } from '@/models/editor/dto/CreateTopicDto';
import { EditTopicDto } from '@/models/editor/dto/EditTopicDto';

Vue.use(Vuex);

interface State {
    isReOrderActive: boolean;
}

const mutations: MutationTree<State> = {
    setIsReOrderActive: (currentState, value: boolean) => (currentState.isReOrderActive = value),
};

const actions: ActionTree<State, any> = {
    async setIsReOrderActive({ commit, dispatch }, value: boolean) {
        commit('setIsReOrderActive', value);
    },

    async createTopic({ commit, dispatch }, topicDto: CreateTopicDto): Promise<Topic> {
        const topic = await editorTopicWebservice.createTopic(topicDto);
        dispatch('configuratorTopic/addAddonTopic', topic, { root: true });
        return topic;
    },

    async saveTopic({ commit, dispatch }, topicDto: EditTopicDto): Promise<Topic> {
        const topic = await editorTopicWebservice.saveTopic(topicDto);
        dispatch('configuratorTopic/updateAddonTopic', topic, { root: true });
        return topic;
    },

    async deleteTopic({ commit, dispatch }, id: number): Promise<Topic> {
        const topic: Topic = await editorTopicWebservice.deleteTopic(id);
        dispatch('configuratorTopic/removeAddonTopic', topic, { root: true });
        return topic;
    },
};

const getters = {
    isReOrderActive(currentState: State) {
        return currentState.isReOrderActive;
    },
};

const state: State = {
    isReOrderActive: false,
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
