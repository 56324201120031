import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import { Photo } from '@/models/preAnalysis/photo';

// Main Sass file
import './assets/sass/setup.scss';

// Font awesome icons
import 'font-awesome/scss/font-awesome.scss';

// Vue-Awesome-Swiper css
import 'swiper/dist/css/swiper.css';

import Buefy from 'buefy';
Vue.use(Buefy, {
    defaultIconPack: 'fa',
    // ...
    // https://buefy.org/documentation/constructor-options
});

// ===== LOCALIZATION =====
// All localization is included here
import i18n from './localization/localization';

import VueAwesomeSwiper from 'vue-awesome-swiper';
// define vue-awesome-swiper globally
Vue.use(VueAwesomeSwiper /* { default global options } */);

// ===== AXIOS =====
// Fetch the token
const token = localStorage.getItem('api_token');

// If the token exist, set it in the authorization
if (token) {
    // Axios configuration
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common.Authorization = 'Bearer '.concat(token);
}

// CURRENT VERSION NUMBER (19004 -> 20 year, 004 day of the year)
Vue.prototype.$version = '2.1.0.21347';

// Define the base api url
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Create a Global CONST Variable , Accessable to all Vue instance
Vue.mixin({
    data() {
        return {
            get VUE_APP_BACKEND_URL() {
                return process.env.VUE_APP_BACKEND_URL;
            },

            FORMAT_PRICE(x: string) {
                if (x !== undefined) {
                    // tslint:disable-next-line
                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
                }
            },

            getImagePath(photo: Photo) {
                if (photo !== undefined) {
                    return process.env.VUE_APP_BACKEND_URL + photo.path;
                }
            },

            getImageThumbPath(photo: Photo) {
                if (photo !== undefined) {
                    return process.env.VUE_APP_BACKEND_URL + photo.path + '.thumbnail-min.jpg';
                }
            },
        };
    },
});

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
