



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/configurator/topic';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import { Action, Getter } from 'vuex-class';

@Component({
    name: 'DeleteTopicButton',
    components: {
        ConfirmModal,
    },
})
export default class DeleteTopicButton extends Vue {
    @Action('editorTopic/deleteTopic')
    private deleteTopic;
    @Prop()
    private topic: Topic;
    private showModal: boolean = false;
    private deleting: boolean = false;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    private toggleConfirmModal() {
        this.showModal = !this.showModal;
    }

    private async removeTopic(shouldDelete: boolean) {
        if (shouldDelete) {
            this.deleting = true;
            await this.deleteTopic(this.topic.id);
        }
        this.toggleConfirmModal();
    }

    get confirmText() {
        return 'Sind Sie sicher, dass Sie das Topic: "' + this.topic.title + '" löschen möchten?';
    }
}
