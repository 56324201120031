

















import { Component, Prop, Vue } from 'vue-property-decorator';
import Navbar from './components/layout/Navbar.vue';
import Footerino from './components/layout/Footerino.vue';
import { Getter } from 'vuex-class';
import { userWebservice } from './webservices/common/user';

@Component({
    name: 'App',
    components: {
        Navbar,
        Footerino,
    },
})
export default class App extends Vue {
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    // Mounted is called initially
    private mounted() {
        if (this.isLoggedIn) {
            this.getUserInfo();
        }
    }

    private getUserInfo() {
        userWebservice.getUserInfo().then((getUserInfoResponse) => {
            this.$store.commit('account/setCurrentUser', getUserInfoResponse.data);
        });
    }
}
