import axios from 'axios';
import { Result } from '@/models/preAnalysis/result';
import { Photo } from '@/models/preAnalysis/photo';

class PhotoWebservice {
    public async getAllPhotos(): Promise<Photo[]> {
        return await axios
            .get('/pre_analysis/v1/photos')
            .then(function(response) {
                const photos: Photo[] = response.data;
                return Promise.resolve(photos);
            })
            .catch(function(error) {
                return Promise.reject([]);
            });
    }
}

export const photoWebservice = new PhotoWebservice();
