import { StoreOption } from './StoreOption';
import { Topic } from '@/models/configurator/topic';
import { Option } from '@/models/configurator/option';
import { Store } from 'vuex';

export class StoreOptionNumber implements StoreOption {
    public optionType: string;

    constructor() {
        this.optionType = 'number';
    }

    public store(topic: Topic, option: Option, target: any, store: Store<any>) {
        // Iterate and replace or add it
        const index = topic.options.findIndex((o) => {
            return o.id === option.id;
        });

        option.value = Number(target.value);
        if (index !== -1) {
            // If it exists, remove it
            topic.options[index].value = option.value;
        } else {
            // If not add it
            topic.options.push(option);
        }

        store.commit('configuratorResult/setSelectedTopic', topic);
    }
}
