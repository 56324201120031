import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';

Vue.use(Vuex);

const actions: ActionTree<{}, any> = {
    RESET_PREANALYSIS({ commit }) {
        // TODO: If we delete the full local storage we also delete the vuex information
        // of the other component (in this case preanalysis)
        // localStorage.clear();
        // commit('account/resetState', null, { root: true });
        commit('preAnalysisResult/resetState', null, { root: true });
        commit('preAnalysisTopic/resetState', null, { root: true });
        commit('preAnalysisWizard/resetState', null, { root: true });
        // Clear also the local storage
    },

    RESET_CONFIGURATOR({ commit }) {
        // TODO: If we delete the full local storage we also delete the vuex information
        // of the other component (in this case preanalysis)
        // localStorage.clear();
        // commit('account/resetState', null, { root: true });
        commit('configuratorResult/resetState', null, { root: true });
        commit('configuratorTopic/resetState', null, { root: true });
        commit('configuratorWizard/resetState', null, { root: true });
        // Clear also the local storage
    },
};

const module = {
    namespaced: true,
    actions,
};

export default module;
