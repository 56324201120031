import axios from 'axios';

import { Topic } from '@/models/preAnalysis/topic';

class TopicWebservice {
    public async getAllTopics(): Promise<Topic[]> {
        return await axios
            .get('/pre_analysis/v1/topic')
            .then(function(response) {
                const topics: Topic[] = response.data;
                return Promise.resolve(topics);
            })
            .catch(function(error) {
                return Promise.reject([]);
            });
    }
}

export const topicWebservice = new TopicWebservice();
