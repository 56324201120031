

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Topic } from '@/models/configurator/topic';
import { Swiper } from 'vue-awesome-swiper';

@Component({
    name: 'DetailModal',
})
export default class DetailModal extends Vue {
    @Prop() private title: string;
    @Prop() private photos: string[];
    @Prop() private imagesOnly: boolean;

    // Data
    private swiperOption = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        speed: 600,
        autoHeight: true,
        // preventIntercationOnTransition:true,
        // allowSlidePrev:false,
        // allowSlideNext:false,
        noSwiping: false, // needed to disable swiping
        noSwipingClass: 'swiper-no-swiping',
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: false,
            type: 'bullets',
        },
    };

    private mounted() {
        if (this.swiper) {
            this.swiper.autoplay.start();
        }
    }

    get swiper() {
        if (this.$refs.photoSwiper) {
            return ((this.$refs.photoSwiper as any) as Swiper).swiper;
        }
    }

    private toggleDetailModal() {
        this.$emit('toggleDetailModal');
    }
}
