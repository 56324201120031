import axios from 'axios';
import HttpException from '@/webservices/configurator/HttpException';
import { OptionType } from '@/models/configurator/optionType';

class OptionTypeWebservice {
    public async getAll(): Promise<OptionType[]> {
        try {
            const response = await axios.get<OptionType[]>('/editor/v1/option_types');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const optionTypeWebservice = new OptionTypeWebservice();
