import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Scope } from '@/models/common/scope';
import { scopeWebservice } from '@/webservices/common/scope';

Vue.use(Vuex);

interface State {
    scopes: Scope[];
}

const mutations: MutationTree<State> = {
    setScopes: (currentState, scopes) => (currentState.scopes = scopes),

    resetState(currentState) {
        currentState.scopes = Array<Scope>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchScopes({ commit }) {
        const scopes = await scopeWebservice.getAll();
        commit('setScopes', scopes);
    },
};

const getters = {
    scopes(currentState: State) {
        return currentState.scopes;
    },
    internScopeId(currentState: State) {
        return 1;
    },
    externScopeId(currentState: State) {
        return 2;
    },
};

const state: State = {
    scopes: Array<Scope>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
