



























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
    name: 'UploadImages',
    components: {},
})
export default class UploadImages extends Vue {
    @Prop({
        default: () => [],
        type: Array,
    })
    private images_file: File[];

    @Prop({
        default: () => [],
        type: Array,
    })
    private images_path: string[];

    private images_path_local: string[] = [];
    private isReady: boolean = false;

    private mounted() {
        if (this.images_path) {
            Object.assign(this.images_path_local, this.images_path);
        }
        this.isReady = true;
    }

    private async toBase64(file: File) {
        const base64Image = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
        return await base64Image;
    }

    private async imageFilesSelected() {
        this.$emit('update:images_file', this.images_file);
        this.images_path_local = [];
        if (this.images_file.length !== 0) {
            for (const imageFile of this.images_file) {
                const base64 = await this.toBase64(imageFile);
                this.images_path_local.push(base64 as string);
            }
        } else {
            this.images_path_local = [];
        }
    }

    private deleteImages() {
        this.images_path_local = [];
        this.$emit('update:images_file', []);
        this.$emit('deleteImages');
    }
}
