import axios from 'axios';
import { Product } from '@/models/configurator/product';
import HttpException from './HttpException';

class ProductWebservice {
    public async getAll(): Promise<Product[]> {
        try {
            const response = await axios.get<Product[]>('/common/v1/products');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Something went wrong loading the data');
        }
    }
}

export const productWebservice = new ProductWebservice();
