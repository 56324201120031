


















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { Dimension } from '@/models/configurator/dimension';
import { Material } from '@/models/configurator/material';
import { Product } from '@/models/configurator/product';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { Result } from '@/models/configurator/result';
import { Option } from '@/models/configurator/option';
import { Topic } from '@/models/configurator/topic';

import OfferMinimal from '@/components/configurator/offer/OfferMinimal.vue';

import GeneralBox from '@/components/configurator/helpers/general/GeneralBox.vue';
import PoolVisualizerImproved from '@/components/configurator/visuals/PoolVisualizerImproved.vue';
import ResetButton from '@/components/configurator/helpers/ResetButton.vue';

import DetailModalButton from '@/components/configurator/helpers/DetailModalButton.vue';

import * as R from 'ramda';
import { Form } from '@/models/configurator/form';

import { SelectionDataHelper } from './SelectionDataHelper';

@Component({
    name: 'Selection',
    components: {
        GeneralBox,
        OfferMinimal,
        PoolVisualizerImproved,
        ResetButton,
        DetailModalButton,
    },
})
export default class Selection extends Vue {
    @Getter('configuratorProduct/products') private products: Product[];
    @Getter('configuratorConstructionMethod/constructionMethods') private constructionMethods: ConstructionMethod[];
    @Getter('configuratorDimension/dimensions') private dimensions: Dimension[];
    @Getter('configuratorMaterial/materials') private materials: Material[];
    @Getter('configuratorTopic/addonTopics') private addonTopics: Topic[];
    @Getter('configuratorForm/forms') private forms: Form[];

    @Getter('configuratorResult/result') private result: Result;

    private selectionDataHelper: SelectionDataHelper = new SelectionDataHelper();

    private mounted() {
        this.setDefaultValues();
    }

    private setProductByProposalTitle(proposalTitle: string) {
        const product = this.products.find((p) => {
            return p.title === proposalTitle;
        });
        if (product !== undefined) {
            this.selectedProduct = product;
        }
    }

    private setDefaultValues() {
        if (this.$route.query.hasOwnProperty('proposal') && !this.$route.query.hasOwnProperty('product')) {
            const proposalTitle = this.$route.query.proposal.toString();
            this.setProductByProposalTitle(proposalTitle);
        } else if (this.$route.query.hasOwnProperty('product')) {
            const productTitle = this.$route.query.product.toString();
            this.setProductByProposalTitle(productTitle);
        } else {
            this.setProductByProposalTitle('Schwimmteich');
        }

        const constructionMethod = this.constructionMethods.find((cm) => {
            return cm.id === 1;
        });
        if (constructionMethod !== undefined && R.isEmpty(this.selectedConstructionMethod)) {
            this.selectedConstructionMethod = constructionMethod;
        }

        const dimension = this.dimensions.find((d) => {
            return d.id === 1;
        });
        if (dimension !== undefined && R.isEmpty(this.selectedDimension)) {
            this.selectedDimension = dimension;
        }

        const material = this.materials.find((m) => {
            if (this.selectedProduct.name === 'biopool' || this.selectedProduct.name === 'naturpool') {
                return m.id === 3;
            } else {
                return m.id === 1;
            }
        });
        if (material !== undefined && R.isEmpty(this.selectedMaterial)) {
            this.selectedMaterial = material;
        }

        // Set the value to the first one
        if (this.forms.length > 0) {
            this.selectedForm = this.forms[0];
        }

        const form = this.forms.find((f) => {
            return f.id === 1;
        });
        if (form !== undefined && R.isEmpty(this.selectedForm)) {
            this.selectedForm = form;
        }

        if (this.result.topics.length === 0) {
            this.addonTopics.forEach((t) => {
                if (t.required) {
                    const topic = JSON.parse(JSON.stringify(t));
                    topic.options = [t.options[0]];
                    this.$store.commit('configuratorResult/setSelectedTopic', topic);
                }

                // Check if it has a default and if its the current selected product
                t.options.forEach((option) => {
                    if (option.default_products?.find((p) => p.id === this.selectedProduct.id) !== undefined) {
                        console.log('Add topic with option: ', t.title, option.text);
                        const clonedTopic = JSON.parse(JSON.stringify(t));
                        clonedTopic.options = [option];
                        this.$store.commit('configuratorResult/setSelectedTopic', clonedTopic);
                    }
                });
            });
        }
    }

    get selectedForm() {
        return this.$store.getters['configuratorResult/selectedForm'];
    }

    set selectedForm(form: Form) {
        this.$store.commit('configuratorResult/setForm', form);
    }

    get selectedConstructionMethod() {
        return this.$store.getters['configuratorResult/selectedConstructionMethod'];
    }

    set selectedConstructionMethod(constructionMethod: ConstructionMethod) {
        // this.removeNonSupportedOptions();
        this.$store.commit('configuratorResult/setConstructionMethod', constructionMethod);

        if (constructionMethod.name === 'diy') {
            const holz = this.materials.find((m) => m.name === 'holz');

            // Always select holz
            if (holz) {
                this.$store.commit('configuratorResult/setMaterial', holz);
                this.selectedMaterial = holz;
            }
        }
    }

    get selectedProduct() {
        return this.$store.getters['configuratorResult/selectedProduct'];
    }

    set selectedProduct(product: Product) {
        if (this.selectedProduct.title !== product.title) {
            this.$router.push({
                path: this.$router.currentRoute.fullPath,
                query: {
                    product: product.title,
                },
            });

            this.$store.commit('configuratorResult/resetAllAddons');

            if (product.name === 'biopool' || product.name === 'naturpool') {
                const kunstoff = this.materials.find((m) => m.name === 'kunstoff');

                if (kunstoff) {
                    this.$store.commit('configuratorResult/setMaterial', kunstoff);
                    this.selectedMaterial = kunstoff;
                }

                // For naturpool also set the form
                if (product.name === 'naturpool') {
                    const architectonic = this.forms.find((f) => f.name === 'architectonic');

                    if (architectonic) {
                        this.$store.commit('configuratorResult/setForm', architectonic);
                        this.selectedForm = architectonic;
                    }
                }
            } else if (product.name === 'schwimmteich') {
                const holz = this.materials.find((m) => m.name === 'holz');

                if (holz) {
                    this.$store.commit('configuratorResult/setMaterial', holz);
                    this.selectedMaterial = holz;
                }
            }

            this.addonTopics.forEach((topic) => {
                // Check if it has a default and if its the current selected product
                topic.options.forEach((option) => {
                    option.default_products?.forEach((p) => {
                        if (p.id === product.id) {
                            const clonedTopic = JSON.parse(JSON.stringify(topic));
                            clonedTopic.options = [option];

                            this.$store.commit('configuratorResult/setSelectedTopic', clonedTopic);
                        }
                    });
                });
            });
        }

        // this.removeNonSupportedOptions();
        this.$store.commit('configuratorResult/setProduct', product);
    }

    get selectedDimension() {
        return this.$store.getters['configuratorResult/selectedDimension'];
    }

    set selectedDimension(dimension: Dimension) {
        this.$store.commit('configuratorResult/setDimension', dimension);
    }

    get selectedMaterial() {
        return this.$store.getters['configuratorResult/selectedMaterial'];
    }

    set selectedMaterial(material: Material) {
        this.$store.commit('configuratorResult/setMaterial', material);
    }

    private isOptionActive(option: Option) {
        return this.isCMActive(option) && this.isProductActive(option);
    }

    private isCMActive(option: Option) {
        return (
            option.construction_methods.find((el) => {
                return el.name === this.selectedConstructionMethod.name;
            }) !== undefined
        );
    }

    private isProductActive(option: Option) {
        return (
            option.products.find((el) => {
                return el.name === this.selectedProduct.name;
            }) !== undefined
        );
    }

    private get readyToContinue() {
        return (
            R.isEmpty(this.selectedMaterial) ||
            R.isEmpty(this.selectedDimension) ||
            R.isEmpty(this.selectedProduct) ||
            R.isEmpty(this.selectedConstructionMethod)
        );
    }

    private goNext() {
        this.$store.commit('configuratorWizard/setCurrentTab', 2);
    }

    private showMaterialOption(material: Material) {
        return (
            !(material.name === 'kunstoff' && this.result.product.name === 'schwimmteich') &&
            !(
                material.name === 'naturstein' &&
                this.result.product.name === 'schwimmteich' &&
                this.result.constructionMethod.name === 'diy'
            )
        );
    }
}
