






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { mapGetters } from 'vuex';

import { topicWebservice } from '@/webservices/preAnalysis/topic';
import { Topic } from '@/models/preAnalysis/topic';

import RadarChart from './chart/RadarChart.vue';
import QuestionsDesktop from './questions/QuestionsDesktop.vue';
import EmailTheResults from './followup/EmailTheResults.vue';
import Proposal from './proposal/Proposal.vue';

// Components
import QuestionsMobile from './questions/QuestionsMobile.vue';

// ScreenHelper
import { ScreenHelper } from '@/helpers/ScreenHelper';

@Component({
    components: {
        RadarChart,
        QuestionsDesktop,
        EmailTheResults,
        Proposal,
        QuestionsMobile,
    },
})
export default class AnalysisQuestions extends Vue {
    // Data
    private topics: Topic[] = new Array<Topic>();
    private screenHelper: ScreenHelper = new ScreenHelper();

    // Mounted is called initially
    private mounted() {
        topicWebservice.getAllTopics().then((topics) => {
            this.topics = topics;

            // Store in vuex
            this.$store.commit('preAnalysisTopic/setTopics', topics);
            this.$store.commit('preAnalysisTopic/setActiveTopic', topics[0]);
        });

        this.screenHelper.detectScreenSize();
    }

    private goNextTopic(topic: Topic) {
        this.$store.commit('preAnalysisTopic/setActiveTopic', this.topics[topic.id + 1]);
    }
}
